import React, { ReactElement, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, Switch, useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import TagManager from "react-gtm-module";

// Imported Service Module Routes //
import { App as OrderFulfillmentApp } from "@americommerce/bc-fulfillment-services/App";
import { App as UnifiedAnalyticsApp } from "@americommerce/bc-mia/App";

// Brand Console Routes //
import { HomeRoute } from "./routes/HomeRoute";
import { SettingsRoute } from "./routes/SettingsRoute";
import { Route404 } from "./routes/404Route";
import { OnlineStoreRoute } from "./routes/OnlineStoreRoute";
import { OrderFulfillmentRoute } from "./routes/ProductPages/OrderFulfillmentRoute";
import { MarketingServicesRoute } from "./routes/ProductPages/MarketingServicesRoute";
import { CustomerServiceRoute } from "./routes/ProductPages/CustomerServiceRoute";
import { BusinessPlanningRoute } from "./routes/ProductPages/BusinessPlanningRoute";
import { DataScienceRoute } from "./routes/ProductPages/DataScienceRoute.tsx";

// Components //
import { ProtectedRoute } from "./auth/ProtectedRoute";
import { Auth0ProviderWithHistory } from "./auth/Auth0ProviderWithHistory";
import { NavBar } from "./components/NavBar";
import { LoadingSpinner } from "./components/LoadingSpinner";

import { ACSingleSignOn } from "./helpers";

import "@americommerce/bootstrap-theme/bootstrap.scss";

declare global {
    interface Window {
        requeryACMyStoresAPI: () => void;
    }
}

export const App = (): ReactElement => {
    const { isAuthenticated, isLoading, user, getAccessTokenSilently, loginWithRedirect, logout } = useAuth0();
    const [loadingMyStores, setLoadingMyStores] = useState(true);
    const [ACStoreName, setACStoreName] = useState("");
    const [ACStoreURL, setACStoreURL] = useState("");
    const [Token, setToken] = useState("");
    const isFromOnlineStore = new URLSearchParams(useLocation().search).get("from-os") === "1";
    /**
     * Google Tag Manager: Initialize Google Tag Manager Conneciton
     */
    const tagManagerArgs = {
        gtmId: process.env.REACT_APP_GTM_ID,
        dataLayer: {
            userId: user?.sub,
        },
        auth: process.env.REACT_APP_GTM_AUTH,
        preview: process.env.REACT_APP_GTM_PREVIEW,
    };
    try {
        TagManager.initialize(tagManagerArgs);
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error("ERROR: ", error);
    }

    // eslint-disable-next-line consistent-return
    const fetchMyStoresAPI = async () => {
        try {
            const httpHeaders = {
                Authorization: `Bearer ${await getAccessTokenSilently()}`,
                "x-cartid-sub": user.sub,
                "x-cartid-email": user.email,
                Accept: "application/json",
                "Content-Type": "application/json",
            };

            const response = await fetch(`${process.env.REACT_APP_MYSTORES_API_URL}`, {
                method: "GET",
                headers: httpHeaders,
            });

            // Let the catch handle all errors.
            if (!response.ok) throw new Error(await response.text());

            const responseJSON = await response.json();

            if (responseJSON.success) {
                return responseJSON;
            }
            // The fetch was successful, but the response was not.
            // Display all the errors the response is sending back.
            throw new Error(responseJSON.errors.toString());
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log("ERROR: ", error);
        }
    };

    // Look into REDUX for handling this.
    window.requeryACMyStoresAPI = async () => {
        const myStoresResponse = await fetchMyStoresAPI();
        setACStoreName(myStoresResponse?.result?.[0]?.provisioningAccounts?.[0]?.sites?.[0]?.name);
        setACStoreURL(myStoresResponse?.result?.[0]?.provisioningAccounts?.[0]?.sites?.[0]?.sharedDomain);
    };

    // When isAuthenticated is updated, fire this function.
    useEffect(() => {
        // Fetch from AC_MYSTORES_API to determine if we have an AC store already.
        // We're doing this once and passing it around so we don't hit that endpoint constantly.
        (async () => {
            if (isAuthenticated) {
                setLoadingMyStores(true);
                const myStoresResponse = await fetchMyStoresAPI();
                setACStoreName(myStoresResponse?.result?.[0]?.provisioningAccounts?.[0]?.sites?.[0]?.name);
                setACStoreURL(myStoresResponse?.result?.[0]?.provisioningAccounts?.[0]?.sites?.[0]?.sharedDomain);
                setLoadingMyStores(false);

                // Prefill Zendesk Chat Widget upon Authentication
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                zE("webWidget", "prefill", {
                    name: {
                        value: user.name,
                    },
                    email: {
                        value: user.email,
                    },
                });
                setToken(await getAccessTokenSilently());
            }
        })();
    }, [isAuthenticated]);

    return (
        <>
            {isAuthenticated && <NavBar />}

            {isLoading || (isAuthenticated && loadingMyStores && <LoadingSpinner fixed />)}

            <Switch>
                <Route path="/login">
                    {() => {
                        loginWithRedirect({
                            screen_hint: "login",
                        });
                    }}
                </Route>
                <Route path="/signup">
                    {() => {
                        loginWithRedirect({
                            screen_hint: "signup",
                            redirectUri: isFromOnlineStore ? `${window.location.origin}/online-store` : null,
                        });
                    }}
                </Route>
                <Route path="/logout">{() => logout({ returnTo: window.location.origin })}</Route>

                <ProtectedRoute
                    exact
                    path="/"
                    component={() =>
                        !loadingMyStores && <HomeRoute ACStoreName={ACStoreName} ACStoreURL={ACStoreURL} />
                    }
                />

                <ProtectedRoute
                    path="/online-store"
                    component={() =>
                        !loadingMyStores &&
                        (ACStoreURL ? (
                            <>
                                {ACSingleSignOn(
                                    `https://${ACStoreURL}/store/admin/login.aspx?fromBc=1`,
                                    Token,
                                    user.sub
                                )}
                            </>
                        ) : (
                            <OnlineStoreRoute />
                        ))
                    }
                />

                <ProtectedRoute
                    path="/order-fulfillment"
                    component={
                        user && user["https://cart.com/app_metadata"]?.provisioningStatus?.wms
                            ? OrderFulfillmentApp
                            : OrderFulfillmentRoute
                    }
                />

                <ProtectedRoute path="/unified-analytics" component={UnifiedAnalyticsApp} />

                <ProtectedRoute path="/marketing-services" component={MarketingServicesRoute} />
                <ProtectedRoute path="/customer-service" component={CustomerServiceRoute} />
                <ProtectedRoute path="/business-planning" component={BusinessPlanningRoute} />
                <ProtectedRoute path="/data-science" component={DataScienceRoute} />

                <ProtectedRoute
                    path="/settings"
                    component={() =>
                        !loadingMyStores && (
                            <SettingsRoute OnlineStoreURL={ACStoreURL ? `https://${ACStoreURL}` : undefined} />
                        )
                    }
                />

                {/* No route above was matched. */}
                <ProtectedRoute path="*" component={Route404} />
            </Switch>
        </>
    );
};

ReactDOM.render(
    <Router>
        <Auth0ProviderWithHistory>
            <App />
        </Auth0ProviderWithHistory>
    </Router>,
    document.getElementById("root")
);
