import { useAuth0 } from "@auth0/auth0-react";
import React, { ReactElement, useState } from "react";
import TagManager from "react-gtm-module";
import { Col, Container, Row, Breadcrumb, Card, Form, Button, Alert } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { ReactComponent as ErrorSVG } from "../images/svgs/error.svg";
import { ReactComponent as CloseButtonSVG } from "../images/svgs/modal-close-button.svg";

import { CreateStoreModal } from "../components/Modals/CreateStoreModal";

export const OnlineStoreRoute = (): ReactElement => {
    const { user, getAccessTokenSilently } = useAuth0();
    TagManager.dataLayer({
        dataLayer: {
            event: "pageview",
            route: "OnlineStore",
        },
    });
    // Store Name
    const [storeName, setStoreName] = useState("");
    const [isStoreNameValid, setIsStoreNameValid] = useState(false);

    // Failure Alert
    const [showFailureAlert, setShowFailureAlert] = useState(false);
    const [failureAlertMessage, setFailureAlertMessage] = useState("Something went wrong.");

    // Create Store Modal
    const [showCreateStoreModal, setShowCreateStoreModal] = useState(false);

    const handleStoreNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        // Validate the storeName client side.
        // The regex is inverted because we're really matching non-alphanumeric characters.
        const regex = /[^A-Za-z0-9]+/g;

        setIsStoreNameValid(
            regex.test(event.target.value.replaceAll(" ", "")) === false &&
                event.target.value.trim().length > 0 &&
                event.target.value.trim().length <= 29
        );

        setStoreName(event.target.value);
    };

    const validateStoreNameOnServer = async () => {
        // Validate the storeName server side before we request provisioning.
        try {
            // Call trialValidation API.
            const validateResponse = await fetch(`${process.env.REACT_APP_TRIAL_VALIDATE_API_URL}`, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${await getAccessTokenSilently()}`,
                    "x-cartid-sub": user.sub,
                    "x-cartid-email": user.email,
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    email: user.email,
                    siteName: storeName,
                }),
            });
            // const validateResponse = await fetch("/api/stub/trial-validate-success.json", {
            //     method: "GET",
            //     headers: {
            //         Authorization: `Bearer ${await getAccessTokenSilently()}`,
            //         "x-cartid-sub": user.sub,
            //         "x-cartid-email": user.email,
            //         Accept: "application/json",
            //         "Content-Type": "application/json",
            //     },
            // });

            const validateResponseJSON = await validateResponse.json();

            if (validateResponseJSON.success) {
                // Open modal and provision store.
                setShowCreateStoreModal(true);
                setShowFailureAlert(false);
            } else {
                // TODO: This error handling logic needs to be refactored.
                validateResponseJSON.errors.forEach((element: { code: number; message: string }) => {
                    const statusCodeMatchArray = element.message.match(/\s\d+(\\r\\n)/g);
                    let statusCode = "";

                    if (statusCodeMatchArray !== null) {
                        statusCode = statusCodeMatchArray[0].trim();
                    }

                    if (element.code === 0 && statusCodeMatchArray === null) {
                        throw new Error(`An error has occured. ${element.message} <small>(#${element.code})</small>`);
                    }

                    if (element.code === 1001) {
                        throw new Error(
                            `Your email, ${user.email}, is already associated to an online store account. Soon you will be able to attach that account here as well, but until then, please log out and create a CartID with a different email address.  <small>(#${element.code})</small>`
                        );
                    } else if (element.code === 0 && statusCode === "1027") {
                        throw new Error(
                            `Your email, ${user.email}, is already associated as a user of an online store account. Soon you will be able to attach that account here as well.  <small>(#${element.code})</small>`
                        );
                    } else if (element.code === 1000) {
                        throw new Error(`An unknown error has occured. <small>(#${element.code})</small>`);
                    }
                });

                throw new Error(
                    validateResponseJSON.errors.map((element: { message: string }) => element.message).join("\n")
                );
            }
        } catch (error) {
            setFailureAlertMessage(error.message);
            setShowFailureAlert(true);
        }
    };

    return (
        <>
            <CreateStoreModal
                show={showCreateStoreModal}
                closeCallback={() => setShowCreateStoreModal(false)}
                storeName={storeName}
            />

            <Container>
                <Row>
                    <Col>
                        <Breadcrumb className="py-24px">
                            <NavLink exact to="/" className="breadcrumb-item">
                                Home
                            </NavLink>
                            <Breadcrumb.Item active className="font-weight-bold">
                                Online Store
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Alert
                            variant="danger"
                            show={showFailureAlert}
                            className="mb-32px py-16px px-24px d-flex align-items-center border-0 shadow-sm"
                        >
                            <ErrorSVG width={24} height={24} className="mr-16px" fill="#FF5A5F" />
                            <div className="w-100">
                                Something went wrong when attempting to validate your store name:
                                <pre className="mb-0">{failureAlertMessage}</pre>
                            </div>
                            <CloseButtonSVG
                                width={14}
                                height={14}
                                fill="#FF5A5F"
                                className="ml-auto close cursor-pointer"
                                onClick={() => setShowFailureAlert(false)}
                            />
                        </Alert>
                    </Col>
                </Row>
                <Row>
                    <Col lg={{ span: 8, offset: 2 }}>
                        <Card className="shadow">
                            <Card.Header className="bg-white border-bottom p-24px">
                                <h2 className="mb-0">Give it a name</h2>
                            </Card.Header>
                            <Card.Body className="p-24px">
                                <p className="lead">
                                    A great store needs a great name! Your online store’s name will also be the
                                    subdomain you’ll use. Don’t worry—this can be changed later in the settings.
                                </p>

                                <Form>
                                    <Form.Label className="mb-0 font-weight-bold">Name Your Online Store</Form.Label>
                                    <Form.Control
                                        placeholder="Your Store Name"
                                        value={storeName}
                                        onChange={handleStoreNameChange}
                                    />
                                    <Form.Label>
                                        {storeName.trim().toLowerCase().replaceAll(" ", "") || (
                                            <span className="text-muted-base">yourstorename</span>
                                        )}
                                        .americommerce.com
                                    </Form.Label>
                                </Form>

                                {!isStoreNameValid && (
                                    <p className="text-danger" style={{ whiteSpace: "pre-wrap" }}>
                                        Store names can not be empty.
                                        <br />
                                        Store names may not exceed 29 characters.
                                        <br />
                                        Valid store names are alphanumeric and do not have special characters.
                                    </p>
                                )}
                            </Card.Body>
                            <Card.Footer className="bg-white text-center p-24px">
                                <Button disabled={!isStoreNameValid} onClick={validateStoreNameOnServer}>
                                    Build My Store
                                </Button>
                            </Card.Footer>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
};
