import React, { ReactElement } from "react";
import { Modal, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import { ReactComponent as CloseButtonSVG } from "images/svgs/modal-close-button.svg";
import { ReactComponent as SuccessCheckSVG } from "images/svgs/success-check.svg";

type ServiceRequestFormModalProps = {
    isShowing: boolean;
    setIsShowing: (boolean) => void;
};

export function ServiceRequestFormModal({ isShowing, setIsShowing }: ServiceRequestFormModalProps): ReactElement {
    const { getAccessTokenSilently } = useAuth0();

    return (
        <Modal
            centered
            show={isShowing}
            onHide={() => {
                setIsShowing(false);
                // reload user data from Auth0 to update status on home page
                // handles click away
                getAccessTokenSilently({ ignoreCache: true });
            }}
        >
            <Modal.Header className="pb-0 justify-content-end">
                <button
                    type="button"
                    className="close"
                    onClick={() => {
                        setIsShowing(false);
                        // reload user data from Auth0 to update status on home page
                        // handles custom close button
                        getAccessTokenSilently({ ignoreCache: true });
                    }}
                >
                    <CloseButtonSVG />
                </button>
            </Modal.Header>
            <Modal.Body className="text-center">
                <SuccessCheckSVG />
                <h2 className="mt-4">Success!</h2>
                <p className="mt-3">A Cart.com representative will be in touch with you soon!</p>
                <Link component={Button} to="/" className="mt-3">
                    Explore more services
                </Link>
            </Modal.Body>
        </Modal>
    );
}
