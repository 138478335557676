/* eslint-disable */
import { useAuth0 } from "@auth0/auth0-react";
import React, { ReactElement, useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { LoadingSpinner } from "../LoadingSpinner";
import messages from "./loading_messages";

import { ReactComponent as OnlineStoreSVG } from "../../images/svgs/create-store-modal/online-store.svg";
import { ReactComponent as ErrorSVG } from "../../images/svgs/create-store-modal/error.svg";
import { ACSingleSignOn } from "../../helpers";

enum CreateStoreModalState {
    "PROVISIONING",
    "SUCCESS",
    "FAILURE",
}

export const CreateStoreModal = ({
    show,
    closeCallback,
    storeName,
}: {
    show: boolean;
    closeCallback: () => void;
    storeName: string;
}): ReactElement => {
    const { getAccessTokenSilently, user } = useAuth0();

    const [modalState, setModalState] = useState(CreateStoreModalState.PROVISIONING);
    const [provisioningRandomMessage, setProvisioningRandomMessage] = useState(
        "Sit tight! We're provisioning your store now!"
    );

    const [provisioningFailureMessage, setProvisioningFailureMessage] = useState("An unspecified error occurred.");

    // On Component Mount //
    useEffect(() => {
        const randomMessageInterval = setInterval(() => {
            setProvisioningRandomMessage(messages[Math.floor(Math.random() * messages.length)]);
        }, 5000);

        return function cleanup() {
            clearInterval(randomMessageInterval);
        };
    }, []);

    const callProvisioningAPI = async () => {
        try {
            const provisionResponse = await fetch(`${process.env.REACT_APP_PROVISION_TRIAL_API_URL}`, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${await getAccessTokenSilently()}`,
                    "x-cartid-sub": user.sub,
                    "x-cartid-email": user.email,
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    email: user.email,
                    siteName: storeName,
                    name: user.name,
                    revenue: user["https://cart.com/user_metadata"].revenue,
                    trialType: "CartDotCom",
                    ipAddress: "127.0.0.1",
                }),
            });
            // const provisionResponse = await fetch("/api/stub/provisioning-trial-failure.json", {
            //     method: "GET",
            //     headers: {
            //         Authorization: `Bearer ${await getAccessTokenSilently()}`,
            //         "x-cartid-sub": user.sub,
            //         "x-cartid-email": user.email,
            //         Accept: "application/json",
            //         "Content-Type": "application/json",
            //     },
            // });

            let provisionResponseJSON;

            if (provisionResponse.ok) {
                provisionResponseJSON = await provisionResponse.json();
            } else {
                try {
                    const respStr = await provisionResponse.text();
                    provisionResponseJSON = JSON.parse(respStr);
                } catch (exception) {
                    throw new Error(await provisionResponse.text());
                }
            }

            if (provisionResponseJSON.success) {
                setModalState(CreateStoreModalState.SUCCESS);
            } else {
                // TODO: This error handling logic needs to be refactored.
                provisionResponseJSON.errors.forEach((element: { code: number; message: string }) => {
                    let statusCode = "";
                    const statusCodeMatchArray = element.message.match(/\s\d+(\\r\\n)/g);
                    if (statusCodeMatchArray !== null) {
                        statusCode = statusCodeMatchArray[0].trim();
                    }

                    if (element.code === 0 && statusCodeMatchArray === null) {
                        throw new Error(`${element.message} (ERR#${element.code})`);
                    }

                    if (element.code === 1001) {
                        throw new Error(
                            `Your email, ${user.email}, is already associated to an online store account. Soon you will be able to attach that account here as well, but until then, please log out and create a CartID with a different email address.  (ERR#${element.code})`
                        );
                    } else if (element.code === 0 && statusCode === "1027") {
                        throw new Error(
                            `Your email, ${user.email}, is already associated as a user of an online store account. Soon you will be able to attach that account here as well.  (ERR#${element.code})`
                        );
                    } else if (element.code === 1000) {
                        throw new Error(`An unknown server error has occured. (ERR#${element.code})`);
                    }
                });

                throw new Error(
                    provisionResponseJSON.errors.map((element: { message: string }) => element.message).join("\n")
                );
            }
        } catch (error) {
            setModalState(CreateStoreModalState.FAILURE);
            setProvisioningFailureMessage(error.message);
        }
    };

    return (
        <Modal
            show={show}
            onShow={callProvisioningAPI}
            onHide={() => {
                closeCallback();
                window.requeryACMyStoresAPI();
            }}
            size="lg"
            backdrop="static"
            centered
        >
            {(() => {
                switch (modalState) {
                    case CreateStoreModalState.PROVISIONING:
                        return (
                            <Modal.Body className="text-center py-48px px-8px">
                                <LoadingSpinner />
                                <h2 className="mb-32px">Creating your new store</h2>
                                <p className="lead w-50 mx-auto mb-32px">
                                    Your new store is being created with Cart.com's powerful, feature-rich ecommerce
                                    online store software!
                                </p>
                                <p className="lead font-italic mb-0">{provisioningRandomMessage}</p>
                            </Modal.Body>
                        );
                    case CreateStoreModalState.SUCCESS:
                        return (
                            <Modal.Body className="text-center py-48px px-8px">
                                <OnlineStoreSVG width={100} height={100} className="d-block mx-auto mb-48px" />
                                <h2 className="mb-32px">Your online store is ready!</h2>

                                <p className="mb-24px">
                                    To add products and configure your store, you will be taken to our powerful online
                                    store platform.
                                </p>
                                <p className="mb-32px">
                                    You may return to Cart.com anytime to add other services to supercharge your online
                                    store.
                                    <br />
                                    Onward in your ecommerce journey! We will be with you each step of the way.
                                </p>
                                <Button
                                    variant="primary"
                                    onClick={async () => {
                                        ACSingleSignOn(
                                            `https://${storeName}.americommerce.com/store/admin/login.aspx?fromBC=1`,
                                            await getAccessTokenSilently(),
                                            user.sub
                                        );
                                    }}
                                >
                                    Let's Go!
                                </Button>
                            </Modal.Body>
                        );
                    case CreateStoreModalState.FAILURE:
                        return (
                            <Modal.Body className="text-center py-48px px-8px">
                                <ErrorSVG width={100} height={100} className="d-block mx-auto mb-48px" />
                                <h2 className="mb-32px">Something went wrong when provisioning your store.</h2>

                                <pre className="mb-24px text-danger">{provisioningFailureMessage}</pre>
                                <p className="mb-32px">
                                    A Cart.com representative has been alerted and will be in touch with you soon.
                                </p>
                                <Button variant="primary" onClick={closeCallback}>
                                    Ok
                                </Button>
                            </Modal.Body>
                        );
                }
            })()}
        </Modal>
    );
};
