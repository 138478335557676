import { useAuth0 } from "@auth0/auth0-react";
import React, { ReactElement } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import TagManager from "react-gtm-module";
import { ACSingleSignOn } from "../../helpers";

export const SettingsOnlineStore = ({
    OnlineStoreURL = "https://ctlachance.americommerce.com",
}: {
    OnlineStoreURL: string;
}): ReactElement => {
    const { user, getAccessTokenSilently } = useAuth0();
    TagManager.dataLayer({
        dataLayer: {
            event: "pageview",
            route: "SettingsOnlineStore",
        },
    });
    return (
        <>
            <h2 className="mb-48px">Online Store</h2>

            <Row className="mb-64px">
                <Col xs={12} lg={4}>
                    <h3>Account Details</h3>
                    <p>Mange your plan and membership type, contact and multiple store information.</p>
                </Col>
                <Col>
                    <Card className="bg-white rounded-lg shadow-sm">
                        <Card.Header className="bg-white border-bottom p-24px">
                            <h3 className="m-0">Online Store Account Details</h3>
                        </Card.Header>
                        <Card.Body className="p-24px">
                            <Button
                                variant="secondary"
                                size="sm"
                                onClick={async () =>
                                    ACSingleSignOn(
                                        `${OnlineStoreURL}/store/admin/account/account.aspx?fromBc=1`,
                                        await getAccessTokenSilently(),
                                        user.sub
                                    )
                                }
                            >
                                Manage Account Details
                            </Button>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <Row className="mb-64px">
                <Col xs={12} lg={4}>
                    <h3>Domain Control</h3>
                    <p>Manage your store URL and shared domain information, site security and DNS settings.</p>
                </Col>
                <Col>
                    <Card className="bg-white rounded-lg shadow-sm">
                        <Card.Header className="bg-white border-bottom p-24px">
                            <h3 className="m-0">Domain Control</h3>
                        </Card.Header>
                        <Card.Body className="p-24px">
                            <Button
                                variant="secondary"
                                size="sm"
                                onClick={async () =>
                                    ACSingleSignOn(
                                        `${OnlineStoreURL}/store/admin/account/domaincontrol.aspx?fromBc=1`,
                                        await getAccessTokenSilently(),
                                        user.sub,
                                    )
                                }
                            >
                                Manage Domain Information
                            </Button>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <Row className="mb-64px">
                <Col xs={12} lg={4}>
                    <h3>User Preferences</h3>
                    <p>
                        Manage user preferences including online store public profile, group roles and other
                        preferences.
                    </p>
                </Col>
                <Col>
                    <Card className="bg-white rounded-lg shadow-sm">
                        <Card.Header className="bg-white border-bottom p-24px">
                            <h3 className="m-0">User Preferences</h3>
                        </Card.Header>
                        <Card.Body className="p-24px">
                            <Button
                                variant="secondary"
                                size="sm"
                                onClick={async () =>
                                    ACSingleSignOn(
                                        `${OnlineStoreURL}/store/admin/settings/users/useredit.aspx?fullpage=1&loginuser=1&fromBc=1&shownav=1`,
                                        await getAccessTokenSilently(),
                                        user.sub
                                    )
                                }
                            >
                                Manage User Preferences
                            </Button>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    );
};
