import { useAuth0 } from "@auth0/auth0-react";
import React, { ReactElement, useState } from "react";
import { Form, Alert, Button, Modal } from "react-bootstrap";

import { ReactComponent as CloseButtonSVG } from "../../images/svgs/modal-close-button.svg";
import { ReactComponent as ErrorSVG } from "../../images/svgs/error.svg";
import { ButtonSpinner } from "../ButtonSpinner";

export const SessionsLogoutModal = ({
    show,
    closeCallback,
    successCallback,
}: {
    show: boolean;
    closeCallback: () => void;
    successCallback: () => void;
}): ReactElement => {
    const { user, getAccessTokenSilently } = useAuth0();
    const [showFailureAlert, setShowFailureAlert] = useState(false);
    const [failureMessage, setFailureMessage] = useState("FAILURE MESSAGE");
    const [currentPassword, setCurrentPassword] = useState("");
    const [showButtonSpinner, setShowButtonSpinner] = useState(false);

    const endAllSessions = async () => {
        const token = await getAccessTokenSilently();

        try {
            setShowButtonSpinner(true);
            const response = await fetch(`${process.env.REACT_APP_AUTH0_USER_API}/sessions`, {
                method: "DELETE",
                headers: {
                    Authorization: `Bearer ${token}`,
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "x-cartid-email": user.email,
                    "x-cartid-sub": user.sub,
                },
                body: JSON.stringify({
                    password: currentPassword,
                }),
            });

            if (!response.ok) {
                throw new Error(`${response.status} - ${await response.text()}`);
            } else {
                // Response is Gucci. Leggo.
                setShowButtonSpinner(false);
                setShowFailureAlert(false);
                closeCallback();
                successCallback();
            }
        } catch (error) {
            setFailureMessage(`${error}`);
            setShowFailureAlert(true);
            setShowButtonSpinner(false);
        }
    };

    return (
        <Modal show={show} onHide={closeCallback} centered>
            <Modal.Header className="bg-muted-lighter">
                <h3 className="m-0">End All Sessions</h3>
                <button type="button" onClick={closeCallback} className="close svg-muted-darkest">
                    <CloseButtonSVG />
                </button>
            </Modal.Header>

            <Modal.Body>
                {/* Failure Alert */}
                <Alert
                    variant="danger"
                    show={showFailureAlert}
                    className="mb-32px py-16px px-24px d-flex align-items-center border-0 shadow-sm"
                >
                    <ErrorSVG width={24} height={24} className="mr-16px" fill="#FF5A5F" />
                    Something went wrong when attempting to sign you out of all sessions:
                    <br />
                    {failureMessage}
                    <CloseButtonSVG
                        width={14}
                        height={14}
                        fill="#FF5A5F"
                        className="ml-auto close cursor-pointer"
                        onClick={() => setShowFailureAlert(false)}
                    />
                </Alert>

                <p>
                    The user will be logged out of all session and may log back in using a valid user name and password
                    combination in addition to multi-factor authentication if enabled.
                </p>
                <b>Please confirm your current password to end all sessions:</b>
                <Form.Control
                    type="password"
                    placeholder="Confirm Your Password"
                    value={currentPassword}
                    onChange={(e) => {
                        setCurrentPassword(e.target.value);
                    }}
                />
            </Modal.Body>

            <Modal.Footer>
                <Button variant="tertiary" className="" onClick={closeCallback}>
                    Cancel
                </Button>
                <Button variant="danger" onClick={endAllSessions}>
                    {showButtonSpinner ? <ButtonSpinner /> : "Confirm Password & End All Sessions"}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
