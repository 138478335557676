import React, { ReactElement } from "react";
import TagManager from "react-gtm-module";
import { Breadcrumb, Button, Card, Col, Container, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { Recommendations } from "components/Recommendations";
import { CustomerServiceForm } from "components/ServiceRequestForms/CustomerServiceForm";
import { Footer } from "components/Footer";
import { StickyHeader } from "components/StickyHeader";

import { ReactComponent as CustomerServiceSVG } from "../../images/svgs/product-pages/customer-service.svg";
import CustomerServiceBannerPNG from "../../images/customer-service-banner.png";

const scrollTo = (anchor: string) => {
    // We're using a function instead of anchor tags because we need to account for the sticky header.
    const element = document.getElementById(anchor);
    if (element) {
        window.scrollTo({
            top: element.offsetTop - 96,
            behavior: "smooth",
        });
    }
};

export const CustomerServiceRoute = (): ReactElement => {
    TagManager.dataLayer({
        dataLayer: {
            event: "pageview",
            route: "CustomerService",
        },
    });

    return (
        <>
            <StickyHeader
                overviewOnClick={() => scrollTo("customer-service-overview")}
                offeringsOnClick={() => scrollTo("customer-service-offerings")}
                requestOnClick={() => scrollTo("customer-service-request")}
            >
                <CustomerServiceSVG height={36} width={36} className="mr-8px" />
                <h4 className="mb-0 mr-16px">Customer Service</h4>
            </StickyHeader>
            <Container>
                <Row>
                    <Col>
                        <Breadcrumb className="my-16px user-select-none">
                            <NavLink exact to="/" className="breadcrumb-item">
                                Home
                            </NavLink>
                            <Breadcrumb.Item active className="font-weight-bold">
                                Customer Service
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                </Row>
                <Row className="mb-64px">
                    <Col>
                        <Card style={{ maxHeight: "245px" }} className="overflow-hidden">
                            <Card.Img
                                src={CustomerServiceBannerPNG}
                                style={{ objectFit: "scale-down", objectPosition: "top right", maxHeight: "300px" }}
                            />
                            <Card.ImgOverlay className="p-32px d-flex">
                                <CustomerServiceSVG width={85} height={85} />
                                <div className="ml-32px">
                                    <h1>Customer Service</h1>
                                    <p className="lead mb-24px">
                                        Increase satisfaction and sales with a white-labeled customer service team
                                    </p>
                                    <Button onClick={() => scrollTo("customer-service-request")}>
                                        Request Service
                                    </Button>
                                </div>
                            </Card.ImgOverlay>
                        </Card>
                    </Col>
                </Row>
                <Row className="mb-48px" id="customer-service-overview">
                    <Col>
                        <h2 className="mb-24px">Overview</h2>
                        <p className="lead mb-0">
                            Our customer service team represents your brand and is trained to learn your products and
                            guide your shoppers through pre and post purchase questions.
                        </p>
                    </Col>
                </Row>
            </Container>
            <Container fluid className="bg-white mb-48px">
                <Container className="py-32px">
                    <Row id="customer-service-offerings">
                        <Col>
                            <h2 className="mb-24px">Service Offerings</h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6} className="mb-24px mb-md-0">
                            <Card className="h-100">
                                <Card.Header className="bg-primary-base p-0 h-8px" />
                                <Card.Body className="bg-muted-lighter">
                                    <h3>Trained Product Experts</h3>
                                    <p className="mb-0">
                                        Invigorate customer engagement with approachable customer service
                                        representatives that are trained to understand your product offering. Our team
                                        will dive deep into videos, MSDS sheets, manuals, FAQ&apos;s, scripts, product
                                        descriptions and documentation to provide an optimal pre and post purchase
                                        experience for your customers.
                                    </p>
                                </Card.Body>
                            </Card>
                        </Col>

                        <Col md={6}>
                            <Card className="h-100">
                                <Card.Header className="bg-primary-base p-0 h-8px" />
                                <Card.Body className="bg-muted-lighter">
                                    <h3>Multichannel Communications</h3>
                                    <p className="mb-0">
                                        Engage with customers via live chat, SMS, messenger, phone, email, social
                                        messages, and more. With the ability to answer product and order related
                                        questions, the Cart.com customer service as a service group turns shoppers into
                                        loyal fans of your brand. The capabilities of our customer service team increase
                                        exponentially when paired with our fulfillment services and online store
                                        platform, which unlocks greater data visibility and deep integration across
                                        functions.
                                    </p>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Container>
            <Container>
                <Row id="customer-service-request">
                    <Col>
                        <h2>Service Request</h2>
                        <p className="lead">Please fill out the below form to request the service.</p>
                    </Col>
                </Row>
                <Row className="mb-48px">
                    <Col sm={12} lg={{ span: 8, offset: 2 }}>
                        <CustomerServiceForm />
                    </Col>
                </Row>
                <Row className="mb-64px">
                    <Col>
                        <h2 className="mb-32px">Recommendations</h2>
                        <Recommendations pageToOmit="/customer-service" />
                    </Col>
                </Row>
            </Container>
            <Footer />
        </>
    );
};
