import { useAuth0 } from "@auth0/auth0-react";
import React, { ReactElement, useState } from "react";
import { Alert, Button, Card, Col, Row } from "react-bootstrap";
import TagManager from "react-gtm-module";
// import { ChangeMFAModal, AuthenticationMethod } from "../../components/Modals/ChangeMFAModal";
import { ChangePasswordModal } from "../../components/Modals/ChangePasswordModal";
import { SessionsLogoutModal } from "../../components/Modals/SessionsLogoutModal";

export const SettingsSecurity = (): ReactElement => {
    const { user, loginWithRedirect } = useAuth0();
    const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
    // const [showChangeMFAModal, setShowChangeMFAModal] = useState(false);
    const [showSessionsLogoutModal, setShowSessionsLogoutModal] = useState(false);

    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [successAlertMessage, setSuccessAlertMessage] = useState("Success alert text!");

    // const [AuthenticationMethodEnabled, setAuthenticationMethodEnabled] = useState<AuthenticationMethod>();
    TagManager.dataLayer({
        dataLayer: {
            event: "pageview",
            route: "SettingsSecurity",
        },
    });
    return (
        <>
            <ChangePasswordModal
                show={showChangePasswordModal}
                closeCallback={() => setShowChangePasswordModal(false)}
                successCallback={() => {
                    setSuccessAlertMessage("Your password was changed successfully!");
                    setShowSuccessAlert(true);
                    setTimeout(() => {
                        loginWithRedirect({
                            screen_hint: "login",
                            login_hint: user.email,
                        });
                    }, 3000);
                }}
            />

            {/* <ChangeMFAModal
                show={showChangeMFAModal}
                closeCallback={() => setShowChangeMFAModal(false)}
                successCallback={(
                    successMessage = "MFA updated successfully!",
                    MFAMethodEnabled: AuthenticationMethod
                ) => {
                    setSuccessAlertMessage(successMessage);
                    setAuthenticationMethodEnabled(MFAMethodEnabled);
                    setShowSuccessAlert(true);
                }}
            /> */}

            <SessionsLogoutModal
                show={showSessionsLogoutModal}
                closeCallback={() => setShowSessionsLogoutModal(false)}
                successCallback={() => {
                    setSuccessAlertMessage("All sessions ended successfully!");
                    setShowSuccessAlert(true);
                    setTimeout(() => {
                        loginWithRedirect({
                            screen_hint: "login",
                            login_hint: user.email,
                        });
                    }, 3000);
                }}
            />

            <Alert
                variant="success"
                dismissible
                show={showSuccessAlert}
                onClose={() => setShowSuccessAlert(false)}
                className="mb-32px"
            >
                {successAlertMessage}
            </Alert>

            <h2 className="mb-48px">Security</h2>

            <Row className="mb-64px">
                <Col xs={12} lg={4}>
                    <h3>Password</h3>
                    <p>Manage your password.</p>
                </Col>
                <Col>
                    <Card className="bg-white rounded-lg shadow-sm">
                        <Card.Header className="bg-white border-bottom px-24px py-16px">
                            <h3 className="mb-0">Password Management</h3>
                        </Card.Header>
                        <Card.Body className="px-24px py-16px">
                            <Button variant="secondary" size="sm" onClick={() => setShowChangePasswordModal(true)}>
                                Change Password
                            </Button>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            {/* <Row className="mb-64px">
                <Col xs={12} lg={4}>
                    <h3>Multifactor Authentication</h3>
                    <p>
                        Multi-factor authentication (MFA) is used to ensure only you access your account. After you log
                        in, you will need to verify your identity with a second authentication method.
                    </p>
                </Col>
                <Col>
                    <Card className="bg-white rounded-lg shadow-sm">
                        <Card.Header className="bg-white border-bottom px-24px py-16px">
                            <div className="d-flex justify-content-between">
                                <h3>Multifactor Authentication</h3>
                                <Badge
                                    variant={
                                        AuthenticationMethodEnabled !== undefined
                                            ? "success-lightest"
                                            : "error-lightest"
                                    }
                                    className={`${
                                        AuthenticationMethodEnabled !== undefined
                                            ? "text-success-darker"
                                            : "text-error-darker"
                                    } d-inline-flex align-items-center font-weight-normal`}
                                >
                                    {AuthenticationMethodEnabled !== undefined ? "Active" : "Disabled"}
                                </Badge>
                            </div>

                            <p className="mb-0">Keep your account extra secure with multifactor authentication.</p>
                        </Card.Header>
                        <Card.Body className="px-24px py-16px">
                            {AuthenticationMethodEnabled !== undefined && (
                                <>
                                    <h4>Method Selected</h4>
                                    <p>{AuthenticationMethod[AuthenticationMethodEnabled]}</p>
                                </>
                            )}
                            <Button
                                variant={AuthenticationMethodEnabled !== undefined ? "primary" : "secondary"}
                                size="sm"
                                onClick={() => setShowChangeMFAModal(true)}
                            >
                                {AuthenticationMethodEnabled !== undefined ? "Change MFA Method" : "Enable MFA"}
                            </Button>
                        </Card.Body>
                    </Card>
                </Col>
            </Row> */}

            <Row>
                <Col xs={12} lg={4}>
                    <h3>Session Management</h3>
                    <p>End all sessions you are currently logged into.</p>
                </Col>
                <Col>
                    <Card className="bg-white rounded-lg shadow-sm">
                        <Card.Header className="bg-white border-bottom px-24px py-16px">
                            <h3>Session Management</h3>
                            <p className="mb-0">Sign out of all active sessions.</p>
                        </Card.Header>
                        <Card.Body className="px-24px py-16px">
                            <Button variant="secondary" size="sm" onClick={() => setShowSessionsLogoutModal(true)}>
                                End All Sessions
                            </Button>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    );
};
