import React, { ReactElement, ReactNode, useEffect, useState } from "react";
import { Nav, Navbar } from "react-bootstrap";

import styles from "./StickyHeader.module.scss";

export const StickyHeader = ({
    children,
    baseTopPosition = 350, // The base position for the header, in distance from 'top'.
    stickyTopPosition = 8, // The distance from the top of the viewport when sticky.
    overviewOnClick,
    offeringsOnClick,
    requestOnClick,
}: {
    children?: ReactNode;
    baseTopPosition?: number;
    stickyTopPosition?: number;
    overviewOnClick: () => void;
    offeringsOnClick: () => void;
    requestOnClick: () => void;
}): ReactElement => {
    const [isHeaderSticky, setIsHeaderSticky] = useState(false);

    // On component mount.
    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            if (scrollPosition > baseTopPosition - stickyTopPosition) {
                setIsHeaderSticky(true);
            } else {
                setIsHeaderSticky(false);
            }
        };

        window.addEventListener("scroll", handleScroll);

        // On unmount.
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <Navbar
            className={`py-12px justify-content-center font-weight-bold bg-white rounded-lg shadow w-100 ${
                isHeaderSticky ? "position-fixed" : "position-absolute"
            }`}
            style={{
                zIndex: 100,
                maxWidth: "912px",
                top: isHeaderSticky ? stickyTopPosition : baseTopPosition,
                left: "50%",
                transform: "translateX(-50%)",
            }}
        >
            <Navbar.Brand
                className={`p-0 d-flex justify-content-end align-items-center border-right ${styles.brand} ${
                    isHeaderSticky && styles.show
                }`}
            >
                {/* Pass the Product SVG and Title as a child of StickyHeader. */}
                {children}
            </Navbar.Brand>
            <Nav>
                <Nav.Link onClick={overviewOnClick} className="py-8px px-16px cursor-pointer">
                    Overview
                </Nav.Link>
                <Nav.Link onClick={offeringsOnClick} className="py-8px px-16px cursor-pointer">
                    Offerings
                </Nav.Link>
                <Nav.Link onClick={requestOnClick} className="py-8px px-16px cursor-pointer">
                    Request
                </Nav.Link>
            </Nav>
        </Navbar>
    );
};
