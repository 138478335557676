import React, { ReactElement } from "react";
import TagManager from "react-gtm-module";
import { Breadcrumb, Button, Card, Col, Container, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { Recommendations } from "components/Recommendations";
import { BusinessPlanningForm } from "components/ServiceRequestForms/BusinessPlanningForm";
import { Footer } from "components/Footer";
import { StickyHeader } from "components/StickyHeader";

import { ReactComponent as BusinessPlanningSVG } from "../../images/svgs/product-pages/business-planning.svg";
import BusinessPlanningBannerPNG from "../../images/business-planning-banner.png";

const scrollTo = (anchor: string) => {
    // We're using a function instead of anchor tags because we need to account for the sticky header.
    const element = document.getElementById(anchor);
    if (element) {
        window.scrollTo({
            top: element.offsetTop - 96,
            behavior: "smooth",
        });
    }
};

export const BusinessPlanningRoute = (): ReactElement => {
    TagManager.dataLayer({
        dataLayer: {
            event: "pageview",
            route: "BusinessPlanning",
        },
    });

    return (
        <>
            <StickyHeader
                overviewOnClick={() => scrollTo("business-planning-overview")}
                offeringsOnClick={() => scrollTo("business-planning-offerings")}
                requestOnClick={() => scrollTo("business-planning-request")}
            >
                <BusinessPlanningSVG height={36} width={36} className="mr-8px" />
                <h4 className="mb-0 mr-16px">Business Planning</h4>
            </StickyHeader>
            <Container>
                <Row>
                    <Col>
                        <Breadcrumb className="my-16px user-select-none">
                            <NavLink exact to="/" className="breadcrumb-item">
                                Home
                            </NavLink>
                            <Breadcrumb.Item active className="font-weight-bold">
                                Business Planning
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                </Row>
                <Row className="mb-64px">
                    <Col>
                        <Card style={{ maxHeight: "245px" }} className="overflow-hidden">
                            <Card.Img
                                src={BusinessPlanningBannerPNG}
                                style={{ objectFit: "scale-down", objectPosition: "top right", maxHeight: "300px" }}
                            />
                            <Card.ImgOverlay className="p-32px d-flex">
                                <BusinessPlanningSVG width={85} height={85} />
                                <div className="ml-32px">
                                    <h1>Business Planning</h1>
                                    <p className="lead mb-24px">
                                        Data-driven growth planning for multichannel ecommerce.
                                    </p>
                                    <Button onClick={() => scrollTo("business-planning-request")}>
                                        Request Service
                                    </Button>
                                </div>
                            </Card.ImgOverlay>
                        </Card>
                    </Col>
                </Row>
                <Row className="mb-48px" id="business-planning-overview">
                    <Col>
                        <h2 className="mb-24px">Overview</h2>
                        <p className="lead mb-0">
                            Our multi-disciplinary experts hail from Google, Facebook, The Home Depot, Wayfair, Walmart,
                            Amazon and some of the biggest ecommerce brands. We are business leaders, analysts,
                            marketers, creatives, and technologists. No matter the size of your brand or the industry
                            you&apos;re in, we&apos;ll work hand-in-hand to optimize and grow your online business.
                        </p>
                    </Col>
                </Row>
            </Container>
            <Container fluid className="bg-white mb-48px">
                <Container className="py-32px">
                    <Row id="business-planning-offerings">
                        <Col>
                            <h2 className="mb-24px">Service Offerings</h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6} lg={4} className="mb-24px">
                            <Card className="h-100">
                                <Card.Header className="bg-primary-base p-0 h-8px" />
                                <Card.Body className="bg-muted-lighter">
                                    <h3>Expansion Strategy</h3>
                                    <p className="mb-0">
                                        Benefit from insight and recommendations from an experienced team of veteran
                                        ecommerce professionals. We can help across a range of areas including product
                                        sourcing and pricing, fundraising, subscription and membership program design,
                                        omnichannel marketing planning, customer data analytics, business process
                                        improvement, tech stack rationalization and integration, and more.
                                    </p>
                                </Card.Body>
                            </Card>
                        </Col>

                        <Col md={6} lg={4} className="mb-24px">
                            <Card className="h-100">
                                <Card.Header className="bg-primary-base p-0 h-8px" />
                                <Card.Body className="bg-muted-lighter">
                                    <h3>Customer and Competitor Insights</h3>
                                    <p className="mb-0">
                                        We use quantitative and qualitative research methods alongside primary and third
                                        party data sources to gather market and customer intelligence, segment and
                                        profile target audiences, position your brand and products and build out a
                                        growth strategy that allows you to compete and win. Whether you&apos;re
                                        launching a new brand, a new product, or moving into new markets, Cart.com can
                                        be an expert thought partner for growing and established brands.
                                    </p>
                                </Card.Body>
                            </Card>
                        </Col>

                        <Col md={6} lg={4} className="mb-24px">
                            <Card className="h-100">
                                <Card.Header className="bg-primary-base p-0 h-8px" />
                                <Card.Body className="bg-muted-lighter">
                                    <h3>Product Pricing</h3>
                                    <p className="mb-0">
                                        Pricing is both an art and a science, and need not be static and must be
                                        increasingly personalized and adaptive. Our team includes pricing economists who
                                        can work with you to analyze competitive pricing, measure price elasticity of
                                        demand, run price sensitivity analyses, and model revenue and various price
                                        points and pricing models to dial in a pricing strategy that drives top line and
                                        bottom line growth.
                                    </p>
                                </Card.Body>
                            </Card>
                        </Col>

                        <Col md={6} className="mb-24px mb-lg-0">
                            <Card className="h-100">
                                <Card.Header className="bg-primary-base p-0 h-8px" />
                                <Card.Body className="bg-muted-lighter">
                                    <h3>Process and Tech Rationalization</h3>
                                    <p className="mb-0">
                                        Process fragmentation, technological debt, disintegrated people, processes, and
                                        technology are common culprits that hinder ecommerce transformation and
                                        scalability. Beyond providing software and services that enable ecommerce, our
                                        team can help establish a strategy for business process and technology stack
                                        improvement transform brands into multichannel success stories. With experience
                                        working with major omnichannel retail brands, we can help even the most complex
                                        companies take control of their digital destiny.
                                    </p>
                                </Card.Body>
                            </Card>
                        </Col>

                        <Col md={6}>
                            <Card className="h-100">
                                <Card.Header className="bg-primary-base p-0 h-8px" />
                                <Card.Body className="bg-muted-lighter">
                                    <h3>Capacity Building</h3>
                                    <p className="mb-0">
                                        People are the foundation of any successful ecommerce enterprise, and hiring the
                                        right people and equipping them with the right training is critical to scale.
                                        Unlike many providers that look to make clients dependent on their services,
                                        Cart.com believes in helping the brands we work with build sustainable ecommerce
                                        organizations. We structure ecommerce teams, develop job descriptions, and train
                                        new and existing personnel on best practices and tools.
                                    </p>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Container>
            <Container>
                <Row id="business-planning-request">
                    <Col>
                        <h2>Service Request</h2>
                        <p className="lead">Please fill out the below form to request the service.</p>
                    </Col>
                </Row>
                <Row className="mb-48px">
                    <Col sm={12} lg={{ span: 8, offset: 2 }}>
                        <BusinessPlanningForm />
                    </Col>
                </Row>
                <Row className="mb-64px">
                    <Col>
                        <h2 className="mb-32px">Recommendations</h2>
                        <Recommendations pageToOmit="/business-planning" />
                    </Col>
                </Row>
            </Container>
            <Footer />
        </>
    );
};
