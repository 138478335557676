import { useAuth0 } from "@auth0/auth0-react";
import React, { ReactElement, useState } from "react";
import { Alert, Button, Form, Modal } from "react-bootstrap";

import type { PasswordChangeRequestBody } from "@americommerce/types";

import { ReactComponent as CloseButtonSVG } from "../../images/svgs/modal-close-button.svg";
import { ReactComponent as ErrorSVG } from "../../images/svgs/error.svg";
import { ButtonSpinner } from "../ButtonSpinner";

export const ChangePasswordModal = ({
    show,
    closeCallback,
    successCallback,
}: {
    show: boolean;
    closeCallback: () => void;
    successCallback: () => void;
}): ReactElement => {
    const { user, getAccessTokenSilently } = useAuth0();

    // Password Strength State //
    enum PasswordStrength {
        "None",
        "VeryWeak",
        "Weak",
        "Good",
        "Great",
    }

    const [passwordStrength, setPasswordStrength] = useState<PasswordStrength>(PasswordStrength.None);
    const [strengthColor, setStrengthColor] = useState("bg-muted-light");
    const [passwordLengthGood, setPasswordLengthGood] = useState(false);
    const [passwordContainsNumbers, setPasswordContainsNumbers] = useState(false);
    const [passwordContainsUpperLower, setPasswordContainsUpperLower] = useState(false);
    const [passwordContainsSpecials, setPasswordContainsSpecials] = useState(false);

    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [passwordsMatch, setPasswordsMatch] = useState(false);

    const [showFailureAlert, setShowFailureAlert] = useState(false);
    const [failureMessage, setFailureMessage] = useState("FAILURE MESSAGE");
    const [showButtonSpinner, setShowButtonSpinner] = useState(false);

    const determinePasswordStrength = (password: string) => {
        const lengthGood = password.length >= 8;
        const containsUpperLower = /[A-Z]/.test(password) && /[a-z]/.test(password);
        const containsNumbers = /[0-9]/.test(password);
        const containsSpecials = /[!@#$%^&*()]/.test(password);
        const strength = [lengthGood, containsUpperLower, containsNumbers, containsSpecials].filter(Boolean).length;

        setPasswordLengthGood(lengthGood);
        setPasswordContainsUpperLower(containsUpperLower);
        setPasswordContainsNumbers(containsNumbers);
        setPasswordContainsSpecials(containsSpecials);
        setPasswordStrength(strength);

        switch (strength) {
            case PasswordStrength.None:
                setStrengthColor("muted-light");
                break;
            case PasswordStrength.VeryWeak:
                setStrengthColor("error-dark");
                break;
            case PasswordStrength.Weak:
                setStrengthColor("accent1-dark");
                break;
            case PasswordStrength.Good:
                setStrengthColor("success-dark");
                break;
            case PasswordStrength.Great:
                setStrengthColor("success-darker");
                break;
            default:
                setStrengthColor("muted-light");
                break;
        }
    };

    const changePassword = async () => {
        const token = await getAccessTokenSilently();

        const requestBody: PasswordChangeRequestBody = {
            currentPassword,
            password: newPassword,
            confirmPassword,
        };

        try {
            setShowButtonSpinner(true);
            const response = await fetch(`${process.env.REACT_APP_AUTH0_USER_API}/password`, {
                method: "PATCH",
                headers: {
                    Authorization: `Bearer ${token}`,
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "x-cartid-email": user.email,
                    "x-cartid-sub": user.sub,
                },
                body: JSON.stringify(requestBody),
            });

            if (!response.ok) {
                throw new Error(`${response.status} - ${await response.text()}`);
            } else {
                // Response is Gucci. Leggo.
                setShowButtonSpinner(false);
                closeCallback();
                successCallback();
            }
        } catch (error) {
            setFailureMessage(`${error}`);
            setShowFailureAlert(true);
            setShowButtonSpinner(false);
        }
    };

    return (
        <Modal show={show} onHide={closeCallback} centered size="lg">
            <Modal.Header className="bg-muted-lighter">
                <h3 className="m-0">Change Password</h3>
                <button type="button" className="close svg-muted-darkest" onClick={closeCallback}>
                    <CloseButtonSVG />
                </button>
            </Modal.Header>
            <Modal.Body>
                {/* Failure Alert */}
                <Alert
                    variant="danger"
                    show={showFailureAlert}
                    className="mb-32px py-16px px-24px d-flex align-items-center border-0 shadow-sm"
                >
                    <ErrorSVG width={24} height={24} className="mr-16px" fill="#FF5A5F" />
                    Something went wrong when attempting to update your password:
                    <br />
                    {failureMessage}
                    <CloseButtonSVG
                        width={14}
                        height={14}
                        fill="#FF5A5F"
                        className="ml-auto close cursor-pointer"
                        onClick={() => setShowFailureAlert(false)}
                    />
                </Alert>

                <Form>
                    <Form.Label className="small font-weight-bold">Confirm Current Password</Form.Label>
                    <Form.Control
                        type="password"
                        placeholder="Current Password"
                        className="mb-16px"
                        value={currentPassword}
                        onChange={(e) => setCurrentPassword(e.target.value)}
                    />

                    <Form.Label className="small font-weight-bold">Enter New Password</Form.Label>
                    <Form.Control
                        type="password"
                        placeholder="New Password"
                        className="mb-16px"
                        value={newPassword}
                        onChange={(e) => {
                            setNewPassword(e.target.value);
                            determinePasswordStrength(e.target.value);
                            setPasswordsMatch(e.target.value === confirmPassword);
                        }}
                    />

                    <div className="d-flex justify-content-between mb-8px">
                        <div
                            className={`h-4px w-25 mr-1 ${
                                passwordStrength > PasswordStrength.None ? `bg-${strengthColor}` : "bg-muted-light"
                            }`}
                        />
                        <div
                            className={`h-4px w-25 mx-1 ${
                                passwordStrength > PasswordStrength.VeryWeak ? `bg-${strengthColor}` : "bg-muted-light"
                            }`}
                        />
                        <div
                            className={`h-4px w-25 mx-1 ${
                                passwordStrength > PasswordStrength.Weak ? `bg-${strengthColor}` : "bg-muted-light"
                            }`}
                        />
                        <div
                            className={`h-4px w-25 ml-1 ${
                                passwordStrength > PasswordStrength.Good ? `bg-${strengthColor}` : "bg-muted-light"
                            }`}
                        />
                    </div>

                    <p className={`text-${strengthColor} text-right font-weight-bold mb-0`}>
                        {passwordStrength === PasswordStrength.None ? "" : PasswordStrength[passwordStrength]}
                    </p>

                    <ul className="pl-16px text-muted-base">
                        <li className={passwordLengthGood && "text-success-dark"}>
                            Must be minimum 8 characters in length
                        </li>
                        <li className={passwordContainsNumbers && "text-success-dark"}>Must contain numbers</li>
                        <li className={passwordContainsUpperLower && "text-success-dark"}>
                            Must contain upper & lower characters
                        </li>
                        <li className={passwordContainsSpecials && "text-success-dark"}>
                            Must contain special characters (&, %, *, etc)
                        </li>
                    </ul>

                    <Form.Label className="small font-weight-bold">Confirm New Password</Form.Label>
                    <Form.Control
                        type="password"
                        placeholder="Confirm New Password"
                        className="mb-4px"
                        value={confirmPassword}
                        onChange={(e) => {
                            setConfirmPassword(e.target.value);
                            setPasswordsMatch(e.target.value === newPassword);
                        }}
                    />

                    {(newPassword.length > 0 || confirmPassword.length > 0) && (
                        <p className={`mb-16px ${passwordsMatch ? "text-success-dark" : "text-muted-base"}`}>
                            Your passwords {passwordsMatch ? "match." : "do not match."}
                        </p>
                    )}
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="tertiary" onClick={closeCallback}>
                    Cancel
                </Button>
                <Button
                    disabled={
                        !(
                            passwordsMatch &&
                            passwordLengthGood &&
                            passwordContainsNumbers &&
                            passwordContainsSpecials &&
                            passwordContainsUpperLower
                        )
                    }
                    onClick={changePassword}
                >
                    {showButtonSpinner ? <ButtonSpinner /> : "Change Password"}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
