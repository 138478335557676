/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { ReactElement, useEffect, useState } from "react";
import { Dropdown, Navbar } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import { Services, ServiceSwitcher } from "./ServiceSwitcher";
import { ReactComponent as CartLogoSVG } from "../images/svgs/cartlogo.svg";
import { ReactComponent as DropDownArrow } from "../images/svgs/dropdown-arrow.svg";

import "./NavBar.scss";

export const NavBar = (): ReactElement => {
    const { user, logout } = useAuth0();

    const [accountOpen, setAccountOpen] = useState(false);

    // const triggerSafariRepaint = () => {
    //     // Safari has a bug where it doesn't repaint properly.
    //     // This function is designed to force trigger a repaint to fix the
    //     // drop shadow and ghosting issue on Safari browsers.
    //     if (navigator.vendor.match(/apple/i)) {
    //         const siteHeader = document.getElementsByTagName("nav")[0];
    //         if (siteHeader) {
    //             siteHeader.style.display = "none";
    //             // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    //             siteHeader.offsetHeight; // no need to store this anywhere, the reference is enough
    //             siteHeader.style.display = "flex";
    //         }
    //     }
    // };

    return (
        <Navbar
            variant="light"
            bg="white"
            expand="sm"
            className="align-items-stretch"
            style={{ zIndex: 1000, filter: "drop-shadow(0px 4px 8px rgba(32, 33, 36, 0.1))" }}
        >
            <Navbar.Toggle className="border-0 py-0" />

            <Link to="/" className="px-24px ">
                <Navbar.Brand>
                    <CartLogoSVG width="71.35" height="40" />
                </Navbar.Brand>
            </Link>

            {/* Services Drop Down Menu */}
            <Navbar.Collapse className="navbar-collapse-position" style={{ top: "4.714rem" }}>
                <ServiceSwitcher />
            </Navbar.Collapse>

            {/* Account Drop Down Menu */}
            <Dropdown
                show={accountOpen}
                onToggle={() => {
                    setAccountOpen(!accountOpen);
                    // triggerSafariRepaint();
                }}
                className="ml-auto"
            >
                <Dropdown.Toggle as="button" bsPrefix="btn" className="h-100 d-flex align-items-center">
                    {/* Desktop Button */}
                    <strong className="d-none d-sm-block">{user.name}</strong>
                    <DropDownArrow className="d-none d-sm-block ml-12px" />
                    {/* Mobile Button */}
                    <div className="d-flex d-sm-none h-24px w-24px justify-content-center align-items-center overflow-hidden rounded-circle bg-muted-base text-white line-height-1">
                        {user.name[0]}
                    </div>
                </Dropdown.Toggle>

                <Dropdown.Menu align="right" className="mt-1 p-0 overflow-hidden">
                    <div className="text-center">
                        <img
                            src={user.picture}
                            alt="User Profile"
                            height={80}
                            width={80}
                            className="rounded-circle mt-24px mb-8px mx-64px"
                        />
                        <div className="mb-8px px-8px font-weight-bold">{user.name}</div>
                        <div className="mb-16px px-8px text-break">{user.email}</div>
                    </div>
                    <Link
                        to="/settings"
                        role="button"
                        className="btn btn-primary d-block mb-24px mx-64px"
                        style={{ whiteSpace: "nowrap" }}
                        onClick={() => setAccountOpen(false)}
                    >
                        Manage Account
                    </Link>
                    <Dropdown.Divider className="my-0" />
                    <Dropdown.Item
                        onClick={() => logout({ returnTo: window.location.origin })}
                        className="text-center font-weight-bold rounded-0"
                    >
                        Sign Out
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </Navbar>
    );
};
