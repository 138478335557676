import React, { ReactElement } from "react";
import TagManager from "react-gtm-module";
import { useAuth0 } from "@auth0/auth0-react";
import { Salesforce } from "@americommerce/types";
import { Form, Button, Dropdown, Container } from "react-bootstrap";
import classNames from "classnames";
import { $enum } from "ts-enum-util";

import { ServiceRequestFormModal } from "components/Modals/ServiceRequestFormModal";
import { generateFormDropdownTitle, getFormDropdownTitleClass } from "helpers";
import { ButtonSpinner } from "../ButtonSpinner";
import "./ServiceRequestForm.css";

type CustomerServiceFormFormProps = {
    className?: string;
    style?: React.CSSProperties;
};

export function CustomerServiceForm({ className, style }: CustomerServiceFormFormProps): ReactElement {
    const { user, getAccessTokenSilently } = useAuth0();
    const userMetadata = user["https://cart.com/user_metadata"];

    const [formState, setFormState] = React.useState<Salesforce.CustomerServiceRequestInterface>({
        cartid: user.sub,
        firstName: user.given_name,
        lastName: user.family_name,
        email: user.email,
        phone: userMetadata.phone,
        company: user.company_name,
        url: "",
        salesChannel: userMetadata.salesChannel,
        revenue: userMetadata.revenue,
        contactPreference: [],
        serviceInterest: [],
        // initializing this to null is technically a type error. let's fix that.
        businessStage: null,
        howCanWeHelp: "",
    });
    const [modalShowing, setModalShowing] = React.useState(false);
    const [submittingForm, setSubmittingForm] = React.useState(false);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { target } = event;
        const { name } = target;
        const value = target.type === "checkbox" ? target.checked : target.value;

        setFormState((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleItemSelect = (key) => (event) => {
        const { target } = event;
        const { name, type } = target;

        if (type === "checkbox") {
            setFormState((prevState) => {
                const itemPresent = prevState[key].includes(name);

                if (itemPresent) {
                    return { ...prevState, [key]: [...prevState[key].filter((item) => item !== name)] };
                }
                return { ...prevState, [key]: [...prevState[key], name] };
            });
        }

        if (type === "radio") {
            setFormState((prevState) => ({ ...prevState, [key]: name }));
        }
    };

    const handleSubmit = async (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        // Remove non-form related data
        const {
            cartid,
            firstName,
            lastName,
            email,
            phone,
            company,
            revenue,
            url,
            salesChannel,
            ...formData
        } = formState;
        TagManager.dataLayer({
            dataLayer: { event: "form_submit", results: { formType: "Customer Service Service Request", ...formData } },
        });
        try {
            setSubmittingForm(true);
            const sfLeadResponse = await fetch(
                `${process.env.REACT_APP_SFLEAD_API_URL}/${Salesforce.LeadTypeOpts.CUSTOMERSERVICE}`,
                {
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${await getAccessTokenSilently()}`,
                        "x-cartid-sub": user.sub,
                        "x-cartid-email": user.email,
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(formState),
                }
            );
            setSubmittingForm(false);
            if (sfLeadResponse.ok) {
                setModalShowing(true);
            } else {
                // eslint-disable-next-line no-console
                console.error(sfLeadResponse.status, sfLeadResponse.statusText);
            }
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error);
        }
    };

    const checkedItemClass = (stateKey: string, valueToCheck): string => {
        if (
            (Array.isArray(formState[stateKey]) && formState[stateKey].includes(valueToCheck)) ||
            formState[stateKey] === valueToCheck
        ) {
            return "px-0 py-1 rounded bg-primary-lightest";
        }

        return "px-0 py-1 rounded";
    };

    const createMenuItemsFromEnum = (theEnum, stateKey: string) =>
        $enum(theEnum).map((value, key) => {
            let finalLabel = value;
            switch (value.toLocaleLowerCase()) {
                case "email":
                    finalLabel = `${value}: ${user.email}`;
                    break;
                case "phone":
                    finalLabel = `${value}: ${userMetadata.phone}`;
                    break;
                default:
                    break;
            }
            return (
                // eslint won't allow key as the key here. probably because the function signature
                // of $enum().map makes it look like the array index.
                <Dropdown.ItemText key={value} className={checkedItemClass(stateKey, value)}>
                    <Form.Check
                        custom
                        className="w-100"
                        id={`${stateKey}-${key}`}
                        name={value}
                        label={finalLabel}
                        onChange={handleItemSelect(stateKey)}
                    />
                </Dropdown.ItemText>
            );
        });

    const createMenuRadioItemsFromEnum = (theEnum, stateKey: string) =>
        $enum(theEnum).map((value, key) => (
            // eslint won't allow key as the key here. probably because the function signature
            // of $enum().map makes it look like the array index.
            <Dropdown.ItemText key={value} className={checkedItemClass(stateKey, value)}>
                <Form.Check
                    custom
                    type="radio"
                    className="w-100"
                    id={`${stateKey}-${key}`}
                    name={value}
                    label={value}
                    checked={formState[stateKey] === value}
                    onChange={handleItemSelect(stateKey)}
                />
            </Dropdown.ItemText>
        ));

    const sendGAFormEvent = (eventKey: string) => {
        TagManager.dataLayer({
            dataLayer: {
                event: "formQuestion_filled",
                results: {
                    formType: "Customer Service Service Request",
                    [eventKey]: formState[eventKey],
                },
            },
        });
    };

    return (
        <Container className={classNames("p-0 shadow", className)} style={style}>
            <Form
                id="customer-service-form"
                className="bg-white p-4 overflow-auto"
                style={{ maxHeight: 585, borderRadius: "6px 6px 0px 0px" }}
            >
                <h4 id="serviceInterest-question" className="mt-2 mb-3">
                    1. What services would you like to hear more about?
                </h4>
                <Dropdown
                    className="border rounded"
                    onToggle={(isOpen: boolean) => {
                        if (!isOpen) sendGAFormEvent("serviceInterest");
                    }}
                >
                    <Dropdown.Toggle
                        variant="muted-lightest"
                        className={getFormDropdownTitleClass(formState.serviceInterest)}
                    >
                        {generateFormDropdownTitle(formState.serviceInterest, "Select all that apply")}
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="w-100">
                        {createMenuItemsFromEnum(Salesforce.CustomerService.ServiceInterestOpts, "serviceInterest")}
                    </Dropdown.Menu>
                </Dropdown>

                <h4 id="businessStage-question" className="mt-2 mb-3">
                    2. What stage is your business in?
                </h4>
                <Dropdown
                    className="border rounded"
                    onToggle={(isOpen: boolean) => {
                        if (!isOpen) sendGAFormEvent("businessStage");
                    }}
                >
                    <Dropdown.Toggle
                        variant="muted-lightest"
                        className={getFormDropdownTitleClass(formState.businessStage)}
                    >
                        {generateFormDropdownTitle(formState.businessStage, "Select the stage of your business")}
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="w-100">
                        {createMenuRadioItemsFromEnum(Salesforce.BusinessStage, "businessStage")}
                    </Dropdown.Menu>
                </Dropdown>

                <h4 id="howCanWeHelp-question" className="mt-4 mb-3">
                    3. How can we help? (optional)
                </h4>
                <Form.Control
                    type="text"
                    as="textarea"
                    name="howCanWeHelp"
                    id="howCanWeHelp"
                    placeholder="Answer here"
                    onChange={handleInputChange}
                    onBlur={() => sendGAFormEvent("howCanWeHelp")}
                />

                <h4 id="contactPreference" className="mt-4 mb-3">
                    4. How would you like to be contacted?
                </h4>
                <Dropdown
                    className="border rounded"
                    onToggle={(isOpen: boolean) => {
                        if (!isOpen) sendGAFormEvent("contactPreference");
                    }}
                >
                    <Dropdown.Toggle
                        variant="muted-lightest"
                        className={getFormDropdownTitleClass(formState.contactPreference)}
                    >
                        {generateFormDropdownTitle(formState.contactPreference, "Select all that apply")}
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="w-100">
                        {createMenuItemsFromEnum(Salesforce.ContactPreferenceTypeOpts, "contactPreference")}
                    </Dropdown.Menu>
                </Dropdown>
            </Form>

            <Container
                className="p-4 d-flex justify-content-center align-items-center bg-white"
                style={{
                    boxShadow: "0px -4px 8px 0px #2021241A",
                    borderRadius: "0px 0px 6px 6px",
                }}
            >
                <Button
                    variant="primary"
                    type="submit"
                    onClick={handleSubmit}
                    disabled={
                        formState.serviceInterest.length === 0 ||
                        !formState.businessStage ||
                        formState.contactPreference.length === 0
                    }
                >
                    {submittingForm ? <ButtonSpinner /> : "Submit"}
                </Button>
            </Container>
            <ServiceRequestFormModal isShowing={modalShowing} setIsShowing={setModalShowing} />
        </Container>
    );
}
