import React, { ReactElement } from "react";
import TagManager from "react-gtm-module";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";

export const Route404 = (): ReactElement => {
    const messages = [
        "Something went wrong. The page you are looking for was not found.",
        "Pay no attention to that page behind the curtain!",
        "I've got a feeling we're not in Cart.com anymore. . .",
        "Well, performance issues, it's not uncommon. One out of five. . .",
        "That's no moon. It's a missing page!",
        "I'm mad as hell, and I'm not going to take these missing pages anymore!",
        "There's no place like the home page.",
        "I'll be back. . . for this page.",
        "Houston, we have a problem.",
        "I feel the need - the need for this page!",
        "I'd like a page. Shaken, not stirred.",
        "Magic Mirror on the wall, where is my page?",
        "Keep your friends close, but your pages closer.",
        "I'm your father's brother's nephew's cousin's former roommate's missing page.",
        "You is kind. You is smart. You is a missin' page.",
        "Page. Missing page.",
        "Pages? Where we're going we don't need pages.",
        "You're killin' me with these pages, Smalls.",
    ];
    TagManager.dataLayer({
        dataLayer: {
            event: "pageview",
            route: "404",
        },
    });
    return (
        <Container fluid className="text-center">
            <h1 className="mt-64px mb-32px">Something&apos;s Missing. . .</h1>
            <p className="mb-32px lead">
                &quot;
                {messages[Math.floor(Math.random() * messages.length)]}
                &quot;
            </p>

            <Link to="/" role="button" className="btn btn-primary" style={{ whiteSpace: "nowrap", minWidth: "25%" }}>
                Go Back
            </Link>
            <div
                className="font-family-gilroy font-weight-bold text-muted-light position-fixed"
                style={{
                    fontSize: "50vw",
                    zIndex: -10,
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                }}
            >
                404
            </div>
        </Container>
    );
};
