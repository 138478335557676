import React, { ReactElement, ReactNode } from "react";
import { useHistory } from "react-router-dom";
import { AppState, Auth0Provider } from "@auth0/auth0-react";

export const Auth0ProviderWithHistory = ({
    children,
}: {
    children: ReactNode;
}): ReactElement => {
    const domain = process.env.REACT_APP_AUTH0_DOMAIN ?? "";
    const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID ?? "";
    const audience = process.env.REACT_APP_AUTH0_AUDIENCE ?? "";
    const history = useHistory();

    const onRedirectCallback = (appState: AppState): void => {
        history.push(appState?.returnTo || window.location.pathname);
    };

    return (
        <Auth0Provider
            domain={domain}
            clientId={clientId}
            redirectUri={window.location.origin}
            onRedirectCallback={onRedirectCallback}
            audience={audience}
        >
            {children}
        </Auth0Provider>
    );
};
