import React, { ReactElement } from "react";
import { Route, RouteProps } from "react-router-dom";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { LoadingSpinner } from "../components/LoadingSpinner";

interface ProtectedRouteProps extends RouteProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    component: React.ComponentType<any>;
    path: string;
}

export const ProtectedRoute = ({
    component,
    ...args
}: ProtectedRouteProps): ReactElement => (
    <Route
        component={withAuthenticationRequired(component, {
            onRedirecting: () => <LoadingSpinner fixed />,
        })}
        // Prop spreading is ok for HOC.
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...args}
    />
);
