import React, { ReactElement, useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";

import { ReactComponent as DropDownArrow } from "../images/svgs/dropdown-arrow.svg";

import { ReactComponent as HomeSVG } from "../images/svgs/home.svg";
import { ReactComponent as OnlineStoreSVG } from "../images/svgs/service-switcher/online-store.svg";
import { ReactComponent as OrderFulfillmentSVG } from "../images/svgs/service-switcher/order-fulfillment.svg";
import { ReactComponent as MarketingServicesSVG } from "../images/svgs/service-switcher/marketing-services.svg";
import { ReactComponent as DataScienceSVG } from "../images/svgs/service-switcher/data-science.svg";
import { ReactComponent as UnifiedAnalyticsSVG } from "../images/svgs/service-switcher/unified-analytics.svg";
import { ReactComponent as CustomerServiceSVG } from "../images/svgs/service-switcher/customer-service.svg";
import { ReactComponent as BusinessPlanningSVG } from "../images/svgs/service-switcher/business-planning.svg";

export enum Services {
    HOME = "Home",
    ONLINE_STORE = "Online Store",
    ORDER_FULFILLMENT = "Order Fulfillment",
    UNIFIED_ANALYTICS = "Unified Analytics",
    MARKETING_SERVICES = "Marketing Services",
    CUSTOMER_SERVICE = "Customer Service",
    BUSINESS_PLANNING = "Business Planning",
    DATA_SCIENCE = "Data Science",
}

export const ServiceSwitcher = (): ReactElement => {
    const location = useLocation();

    const [servicesOpen, setServicesOpen] = useState(false);
    const [activeService, setActiveService] = useState(Services.HOME);

    const renderServiceSVG = (service: Services) => {
        switch (service) {
            case Services.ONLINE_STORE:
                return <OnlineStoreSVG height={20} width={20} className="mr-8px" />;
            case Services.ORDER_FULFILLMENT:
                return <OrderFulfillmentSVG height={20} width={20} className="mr-8px" />;
            case Services.UNIFIED_ANALYTICS:
                return <UnifiedAnalyticsSVG height={20} width={20} className="mr-8px" />;
            case Services.MARKETING_SERVICES:
                return <MarketingServicesSVG height={20} width={20} className="mr-8px" />;
            case Services.CUSTOMER_SERVICE:
                return <CustomerServiceSVG height={20} width={20} className="mr-8px" />;
            case Services.BUSINESS_PLANNING:
                return <BusinessPlanningSVG height={20} width={20} className="mr-8px" />;
            case Services.DATA_SCIENCE:
                return <DataScienceSVG height={20} width={20} className="mr-8px" />;
            default:
                return <HomeSVG height={20} width={20} fill="#4A4B4D" className="mr-12px" />;
        }
    };

    useEffect(() => {
        // Determine the active service based on the route.
        (() => {
            // TODO: Refactor this.
            if (location.pathname.startsWith("/online-store")) {
                setActiveService(Services.ONLINE_STORE);
            } else if (location.pathname.startsWith("/order-fulfillment")) {
                setActiveService(Services.ORDER_FULFILLMENT);
            } else if (location.pathname.startsWith("/unified-analytics")) {
                setActiveService(Services.UNIFIED_ANALYTICS);
            } else if (location.pathname.startsWith("/marketing-services")) {
                setActiveService(Services.MARKETING_SERVICES);
            } else if (location.pathname.startsWith("/customer-service")) {
                setActiveService(Services.CUSTOMER_SERVICE);
            } else if (location.pathname.startsWith("/business-planning")) {
                setActiveService(Services.BUSINESS_PLANNING);
            } else if (location.pathname.startsWith("/data-science")) {
                setActiveService(Services.DATA_SCIENCE);
            } else {
                setActiveService(Services.HOME);
            }
        })();
    }, [location]);

    return (
        <Dropdown
            show={servicesOpen}
            onToggle={() => {
                setServicesOpen(!servicesOpen);
                // triggerSafariRepaint();
            }}
            className="d-flex align-items-center h-100"
        >
            <Dropdown.Toggle
                as="button"
                bsPrefix="btn"
                className="d-flex align-items-center bg-white border rounded border-muted-light text-muted-dark px-16px py-12px"
                style={{ height: "3.143rem", minWidth: "18.286rem" }}
            >
                {renderServiceSVG(activeService)}
                <span className="mr-auto">{activeService}</span>
                <DropDownArrow className="ml-12px" />
            </Dropdown.Toggle>

            <Dropdown.Menu
                className="p-0 shadow-none"
                style={{ top: 0, borderTopLeftRadius: 0, borderTopRightRadius: 0, minWidth: "18.286rem" }}
            >
                <Dropdown.Toggle
                    as="button"
                    bsPrefix="btn"
                    className="d-flex align-items-center text-muted-dark px-16px py-12px w-100"
                    style={{ height: "4.571rem" }}
                >
                    {renderServiceSVG(activeService)}
                    <span className="mr-auto">{activeService}</span>
                    <DropDownArrow className="ml-12px" transform="scale(-1)" />
                </Dropdown.Toggle>

                <Dropdown.Divider className="mt-0 mx-8px" />
                <div className="p-8px">
                    <Dropdown.Header>Products & Services</Dropdown.Header>
                    {activeService !== Services.ONLINE_STORE && (
                        <Link
                            to="/online-store"
                            onClick={() => {
                                setActiveService(Services.ONLINE_STORE);
                                setServicesOpen(false);
                            }}
                            className="dropdown-item font-weight-bold font-family-gilroy"
                        >
                            <OnlineStoreSVG height={24} width={24} className="mr-8px" />
                            {Services.ONLINE_STORE}
                        </Link>
                    )}
                    {activeService !== Services.ORDER_FULFILLMENT && (
                        <Link
                            to="/order-fulfillment"
                            onClick={() => {
                                setActiveService(Services.ORDER_FULFILLMENT);
                                setServicesOpen(false);
                            }}
                            className="dropdown-item font-weight-bold font-family-gilroy"
                        >
                            <OrderFulfillmentSVG height={24} width={24} className="mr-8px" />
                            {Services.ORDER_FULFILLMENT}
                        </Link>
                    )}
                    {activeService !== Services.UNIFIED_ANALYTICS && (
                        <Link
                            to="/unified-analytics"
                            onClick={() => {
                                setActiveService(Services.UNIFIED_ANALYTICS);
                                setServicesOpen(false);
                            }}
                            className="dropdown-item font-weight-bold font-family-gilroy"
                        >
                            <UnifiedAnalyticsSVG height={24} width={24} className="mr-8px" />
                            {Services.UNIFIED_ANALYTICS}
                        </Link>
                    )}
                    {activeService !== Services.MARKETING_SERVICES && (
                        <Link
                            to="/marketing-services"
                            onClick={() => {
                                setActiveService(Services.MARKETING_SERVICES);
                                setServicesOpen(false);
                            }}
                            className="dropdown-item font-weight-bold font-family-gilroy"
                        >
                            <MarketingServicesSVG height={24} width={24} className="mr-8px" />
                            {Services.MARKETING_SERVICES}
                        </Link>
                    )}
                    {activeService !== Services.CUSTOMER_SERVICE && (
                        <Link
                            to="/customer-service"
                            onClick={() => {
                                setActiveService(Services.CUSTOMER_SERVICE);
                                setServicesOpen(false);
                            }}
                            className="dropdown-item font-weight-bold font-family-gilroy"
                        >
                            <CustomerServiceSVG height={24} width={24} className="mr-8px" />
                            {Services.CUSTOMER_SERVICE}
                        </Link>
                    )}
                    {activeService !== Services.BUSINESS_PLANNING && (
                        <Link
                            to="/business-planning"
                            onClick={() => {
                                setActiveService(Services.BUSINESS_PLANNING);
                                setServicesOpen(false);
                            }}
                            className="dropdown-item font-weight-bold font-family-gilroy"
                        >
                            <BusinessPlanningSVG height={24} width={24} className="mr-8px" />
                            {Services.BUSINESS_PLANNING}
                        </Link>
                    )}
                    {activeService !== Services.DATA_SCIENCE && (
                        <Link
                            to="/data-science"
                            onClick={() => {
                                setActiveService(Services.DATA_SCIENCE);
                                setServicesOpen(false);
                            }}
                            className="dropdown-item font-weight-bold font-family-gilroy"
                        >
                            <DataScienceSVG height={24} width={24} className="mr-8px" />
                            {Services.DATA_SCIENCE}
                        </Link>
                    )}

                    {activeService !== Services.HOME && (
                        <>
                            <Dropdown.Header className="mt-16px">Admin Console</Dropdown.Header>

                            <Link
                                to="/"
                                onClick={() => {
                                    setActiveService(Services.HOME);
                                    setServicesOpen(false);
                                }}
                                className="dropdown-item font-weight-bold font-family-gilroy"
                            >
                                <HomeSVG
                                    height={24}
                                    width={24}
                                    fill="#B6B7B9"
                                    className="mr-16px rounded svg-muted-dark"
                                />
                                {Services.HOME}
                            </Link>
                        </>
                    )}
                </div>
            </Dropdown.Menu>
        </Dropdown>
    );
};
