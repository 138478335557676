/**
 * Helper function for navigating to a given AmeriCommerce Admin Console url via Single-Sign-On.
 *
 * @param url - The FULL url to which you're navigating.
 * @param token - The auth0 x-cartid-token value. Formatted in the following fashion: 'Bearer YOUR_TOKEN_HERE'.
 * @param sub - The auth0 x-cartid-sub value. Formatted in the following fashion: 'auth0|YOUR_SUB_ID_HERE'.
 */
export const ACSingleSignOn = (url: string, token: string, sub: string): void => {
    const form = document.createElement("form");
    document.body.appendChild(form);

    form.method = "POST";
    form.action = url;

    // Sub and Bearer Token
    const userSub = document.createElement("input");
    const userToken = document.createElement("input");
    userSub.type = "hidden";
    userToken.type = "hidden";
    userSub.name = "x-cartid-sub";
    userToken.name = "x-cartid-token";
    userSub.value = sub;
    userToken.value = `Bearer ${token}`;

    form.appendChild(userSub);
    form.appendChild(userToken);

    form.submit();
};

export function generateFormDropdownTitle(stateToCheck: string[] | string, defaultTitle: string): string {
    if (Array.isArray(stateToCheck) && stateToCheck.length) {
        return stateToCheck.join("; ");
    }
    if (stateToCheck && typeof stateToCheck === "string") {
        return stateToCheck;
    }
    return defaultTitle;
}

export function getFormDropdownTitleClass(stateToCheck: string[] | string): string {
    let textClass = "text-muted-base";
    if ((Array.isArray(stateToCheck) && stateToCheck.length) || (stateToCheck && typeof stateToCheck === "string")) {
        textClass = "text-body";
    }
    return `w-100 d-flex justify-content-between align-items-center pl-2 pr-2 font-weight-normal overflow-hidden ${textClass}`;
}
