/* eslint-disable import/no-default-export */
// Default export ok for this file. It's just a string holding array.
export default [
    "Fluxing capacitors...",
    "Recording tricorders...",
    "Launching roadsters...",
    "Ramming ramjets...",
    "Quantuming quantums...",
    "Running kessels (in 12 parsecs)...",
    "Rendering tesseracts...",
    "Reticulating splines...",
    "Swapping time and space...",
    "Spinning violently around the y-axis...",
    "Tokenizing real life...",
    "Bending the spoon...",
    "Pay no attention to the man behind the curtain...",
    "Checking the gravitational constant in your locale...",
    "Following the white rabbit...",
    "I left my keys in my other loading screen...",
    "Recombobulating plumbus...",
    "Don't panic...you brought your towel right?",
    "So, do you come here often...?",
    "I'm sorry Dave, I can't do that...",
    "What was I supposed to be doing again...?",
    "Keeping all the 1s and removing all the 0s...",
    "Pressing super-colliding super-button...",
    "Incinerating companion cube... you monster.",
    "Convincing AI not to turn evil...",
    "Computing the answer to life, the universe, and everything...",
    "Constructing additional pylons...",
    "Calculating the airspeed velocity of an unladen swallow...",
    "Dividing by zero...",
    "Cracking military-grade encryption...",
    "Simulating traveling salesman...",
    "Proving P=NP...",
    "Entangling superstrings...",
    "Twiddling thumbs...",
    "Switching to the latest JS framework...",
    "Whatever you do, don't look behind you...",
    "Mining Bitcoin...",
    "Downloading more RAM...",
    "Running with scissors...",
    "Boldly going where no one has gone before...",
];
