import { useAuth0 } from "@auth0/auth0-react";
import React, { ReactElement, useState } from "react";
import TagManager from "react-gtm-module";
import { Alert, Button, Col, Form, Row } from "react-bootstrap";
import type { ProfileUpdateRequestBody } from "@americommerce/types";
import { ButtonSpinner } from "../../components/ButtonSpinner";
import { ReactComponent as SuccessCheckSVG } from "../../images/svgs/success-check.svg";
import { ReactComponent as ErrorSVG } from "../../images/svgs/error.svg";
import { ReactComponent as CloseButtonSVG } from "../../images/svgs/modal-close-button.svg";

export const SettingsProfile = (): ReactElement => {
    const { user, getAccessTokenSilently } = useAuth0();
    const userMetadata = user["https://cart.com/user_metadata"];

    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [showFailureAlert, setShowFailureAlert] = useState(false);
    const [failureMessage, setFailureMessage] = useState("FAILURE MESSAGE");
    const [showButtonSpinner, setShowButtonSpinner] = useState(false);

    const [profileObject, setProfileObject] = useState({
        firstName: user?.given_name ?? "",
        lastName: user?.family_name ?? "",
        companyName: userMetadata?.company_name ?? "",
        email: user?.email ?? "",
        phoneNumber: userMetadata?.phone ?? "",
        street1: userMetadata?.address?.street1 ?? "",
        street2: userMetadata?.address?.street2 ?? "",
        country: userMetadata?.address?.country ?? "",
        state: userMetadata?.address?.state ?? "",
        city: userMetadata?.address?.city ?? "",
        postalCode: userMetadata?.address?.postalCode ?? "",
    });

    TagManager.dataLayer({
        dataLayer: {
            event: "pageview",
            route: "SettingsProfile",
        },
    });

    const updateUserInfo = async () => {
        const token = await getAccessTokenSilently();

        const requestBody: ProfileUpdateRequestBody = {
            firstName: profileObject.firstName,
            lastName: profileObject.lastName,
            company_name: profileObject.companyName,
            email: profileObject.email,
            phone: profileObject.phoneNumber,
            address: {
                street1: profileObject.street1,
                street2: profileObject.street2,
                country: profileObject.country,
                state: profileObject.state,
                city: profileObject.city,
                postalCode: profileObject.postalCode,
            },
        };

        try {
            setShowButtonSpinner(true);
            const response = await fetch(`${process.env.REACT_APP_AUTH0_USER_API}/profile`, {
                method: "PATCH",
                headers: {
                    Authorization: `Bearer ${token}`,
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "x-cartid-email": user.email,
                    "x-cartid-sub": user.sub,
                },
                body: JSON.stringify(requestBody),
            });
            if (!response.ok) {
                throw new Error(`${response.status} - ${await response.text()}`);
            } else {
                // Response is Gucci. Leggo.
                setShowSuccessAlert(true);
                setShowFailureAlert(false);
                setShowButtonSpinner(false);

                await getAccessTokenSilently({ ignoreCache: true });
            }
        } catch (error) {
            setFailureMessage(`${error}`);
            setShowSuccessAlert(false);
            setShowFailureAlert(true);
            setShowButtonSpinner(false);
        }
    };

    return (
        <>
            {/* Success Alert */}
            <Alert
                variant="success"
                show={showSuccessAlert}
                className="mb-32px py-16px px-24px d-flex align-items-center border-0 shadow-sm"
            >
                <SuccessCheckSVG width={24} height={24} className="mr-16px" fill="#41D3BD" />
                Profile information changed successfully!
                <CloseButtonSVG
                    width={14}
                    height={14}
                    fill="#41D3BD"
                    className="ml-auto close cursor-pointer"
                    onClick={() => setShowSuccessAlert(false)}
                />
            </Alert>

            {/* Failure Alert */}
            <Alert
                variant="danger"
                show={showFailureAlert}
                className="mb-32px py-16px px-24px d-flex align-items-center border-0 shadow-sm"
            >
                <ErrorSVG width={24} height={24} className="mr-16px" fill="#FF5A5F" />
                Something went wrong when attempting to update your profile:
                <br />
                {failureMessage}
                <CloseButtonSVG
                    width={14}
                    height={14}
                    fill="#FF5A5F"
                    className="ml-auto close cursor-pointer"
                    onClick={() => setShowFailureAlert(false)}
                />
            </Alert>

            <h2 className="mb-48px">Profile</h2>

            <Row className="mb-64px">
                <Col xs={12} lg={4}>
                    <h3>General Information</h3>
                    <p>This is your basic profile information.</p>
                </Col>
                <Col>
                    <Form className="bg-white py-24px px-32px rounded-lg shadow-sm">
                        <Row>
                            <Col>
                                <Form.Group>
                                    <Form.Label>First Name</Form.Label>
                                    <Form.Control
                                        placeholder="First Name"
                                        value={profileObject.firstName}
                                        onChange={(e) =>
                                            setProfileObject({ ...profileObject, firstName: e.target.value })
                                        }
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group>
                                    <Form.Label>Last Name</Form.Label>
                                    <Form.Control
                                        placeholder="Last Name"
                                        value={profileObject.lastName}
                                        onChange={(e) =>
                                            setProfileObject({ ...profileObject, lastName: e.target.value })
                                        }
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <Form.Label>Company</Form.Label>
                                    <Form.Control
                                        placeholder="Company Name"
                                        value={profileObject.companyName}
                                        onChange={(e) =>
                                            setProfileObject({ ...profileObject, companyName: e.target.value })
                                        }
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>

            <Row className="mb-32px">
                <Col xs={12} lg={4}>
                    <h3>Contact Information</h3>
                    <p>
                        This is the personal information you use to access and manage your account. You can also add a
                        mobile phone number and secondary email for account security and support.
                    </p>
                </Col>
                <Col>
                    <Form className="bg-white py-24px px-32px rounded-lg shadow-sm">
                        <Row>
                            <Col>
                                <Form.Group>
                                    <Form.Label>Email Address</Form.Label>
                                    <Form.Control
                                        placeholder="Email"
                                        value={profileObject.email}
                                        onChange={(e) => setProfileObject({ ...profileObject, email: e.target.value })}
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group>
                                    <Form.Label>Phone Number</Form.Label>
                                    <Form.Control
                                        placeholder="Phone Number"
                                        value={profileObject.phoneNumber}
                                        onChange={(e) =>
                                            setProfileObject({ ...profileObject, phoneNumber: e.target.value })
                                        }
                                    />
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <Form.Group>
                                    <Form.Label>Street Address 1</Form.Label>
                                    <Form.Control
                                        placeholder="Street Address 1"
                                        value={profileObject.street1}
                                        onChange={(e) =>
                                            setProfileObject({ ...profileObject, street1: e.target.value })
                                        }
                                    />
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <Form.Group>
                                    <Form.Label>Street Address 2</Form.Label>
                                    <Form.Control
                                        placeholder="Street Address 2"
                                        value={profileObject.street2}
                                        onChange={(e) =>
                                            setProfileObject({ ...profileObject, street2: e.target.value })
                                        }
                                    />
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <Form.Group>
                                    <Form.Label>Country / Region</Form.Label>
                                    <Form.Control
                                        placeholder="Country"
                                        value={profileObject.country}
                                        onChange={(e) =>
                                            setProfileObject({ ...profileObject, country: e.target.value })
                                        }
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group>
                                    <Form.Label>State / Province</Form.Label>
                                    <Form.Control
                                        placeholder="State"
                                        value={profileObject.state}
                                        onChange={(e) => setProfileObject({ ...profileObject, state: e.target.value })}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <Form.Group>
                                    <Form.Label>City</Form.Label>
                                    <Form.Control
                                        placeholder="City"
                                        value={profileObject.city}
                                        onChange={(e) => setProfileObject({ ...profileObject, city: e.target.value })}
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group>
                                    <Form.Label>Postal Code</Form.Label>
                                    <Form.Control
                                        placeholder="Postal Code"
                                        value={profileObject.postalCode}
                                        onChange={(e) =>
                                            setProfileObject({ ...profileObject, postalCode: e.target.value })
                                        }
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>

            <Row>
                <Col>
                    <Button className="float-right" onClick={updateUserInfo}>
                        {showButtonSpinner ? <ButtonSpinner /> : "Save"}
                    </Button>
                </Col>
            </Row>
        </>
    );
};
