import { useAuth0 } from "@auth0/auth0-react";
import React, { Fragment, ReactElement, useState } from "react";
import { Button, Card, Col, Container, Row, Image, Figure, Badge } from "react-bootstrap";
import { Link } from "react-router-dom";
import TagManager from "react-gtm-module";
import { Footer } from "../components/Footer";

// SVGs //
import { ReactComponent as OnlineStoreSVG } from "../images/svgs/home-route/online-store.svg";
import { ReactComponent as OrderFulfillmentSVG } from "../images/svgs/home-route/order-fulfillment.svg";
import { ReactComponent as UnifiedAnalyticsSVG } from "../images/svgs/home-route/unified-analytics.svg";
import { ReactComponent as MarketingServicesSVG } from "../images/svgs/home-route/marketing-services.svg";
import { ReactComponent as CustomerServiceSVG } from "../images/svgs/home-route/customer-service.svg";
import { ReactComponent as BusinessPlanningSVG } from "../images/svgs/home-route/business-planning.svg";
import { ReactComponent as DataScienceSVG } from "../images/svgs/home-route/data-science.svg";

import Image1 from "../images/carousel/image1.png";
import Image2 from "../images/carousel/image2.png";

export const HomeRoute = ({ ACStoreName, ACStoreURL }: { ACStoreName?: string; ACStoreURL?: string }): ReactElement => {
    const { user } = useAuth0();

    const userMetadata = user["https://cart.com/user_metadata"];
    const appMetadata = user["https://cart.com/app_metadata"];
    TagManager.dataLayer({
        dataLayer: {
            event: "pageview",
            route: "Home",
        },
    });
    // Genereate index (0-1) for random hero banner
    const bannerIndex = Math.round(Math.random());
    // prettier-ignore
    const [servicesRequested] = useState(
        Object.values(appMetadata?.leadStatus?.creativeServices ?? false).includes(true) ||
        Object.values(appMetadata?.leadStatus?.digitalMarketing ?? false).includes(true) ||
        Object.values(appMetadata?.leadStatus?.orderFulfillment ?? false).includes(true) ||
        Object.values(appMetadata?.leadStatus?.customerService ?? false).includes(true) ||
        Object.values(appMetadata?.leadStatus?.paymentProcessing ?? false).includes(true) ||
        Object.values(appMetadata?.leadStatus?.businessFinancing ?? false).includes(true)
    );

    // Captions for banner
    const caption1 = (
        <Figure.Caption
            className="text-muted-darker text-left h-100 p-32px d-flex flex-column align-items-start justify-content-between position-absolute"
            style={{ top: 0, left: 0, width: "45%" }}
        >
            <h1>Expand your reach and drive acquisition</h1>
            <p className="lead">
                Leverage Cart.com&apos;s proven strategic, marketing, and creative expertise to fill the top of your
                sales funnel and acquire more customers.
            </p>
            <Link id="Banner1" to="/marketing-services" component={Button} className="btn-secondary">
                Learn More
            </Link>
        </Figure.Caption>
    );
    const caption2 = (
        <Figure.Caption
            className="text-muted-darker text-left h-100 p-32px d-flex flex-column align-items-start justify-content-between position-absolute"
            style={{ top: 0, left: 0, width: "50%" }}
        >
            <h1>Boost conversion and drive revenue growth while increasing efficiency</h1>
            <p className="lead">
                Turbocharge your direct-to-consumer site and efficiently convert visitors into customers while driving
                up AOV, ROAS, and LTV.
            </p>
            <Link id="Banner2" to="/marketing-services" component={Button} className="btn-secondary">
                Learn More
            </Link>
        </Figure.Caption>
    );

    // Combine banner image and caption into array for randomization
    const bannerArray = [
        { image: Image1, caption: caption1 },
        { image: Image2, caption: caption2 },
    ];

    const enabledBadge = (
        <Badge
            variant="success-lightest"
            className="text-success-darker d-inline-flex align-items-center small-font-size align-top"
            style={{ fontWeight: 500 }}
        >
            <span className="rounded bg-success w-8px h-8px mr-8px" />
            Enabled
        </Badge>
    );

    const requestedBadge = (
        <Badge
            variant="info-lightest"
            className="text-info-darker d-inline-flex align-items-center small-font-size align-top"
            style={{ fontWeight: 500 }}
        >
            Requested
        </Badge>
    );
    return (
        <>
            <Container className="my-64px p-0">
                {/* Header */}
                <Row className="mb-48px mx-0">
                    <Col>
                        {(ACStoreURL && ACStoreName) || userMetadata?.connectedStore?.url || servicesRequested ? (
                            <h1 className="mb-0">Welcome, {user.name}</h1>
                        ) : (
                            <h1 className="mb-0">Hey {user.given_name}, Welcome to Cart.com!</h1>
                        )}
                    </Col>
                </Row>
                {/* Hero Banner */}
                <Row className="mx-0 mb-32px d-none d-xl-block">
                    <Col xs={12} lg={12}>
                        <Figure className="position-relative overflow-hidden">
                            <Figure.Image
                                src={bannerArray[bannerIndex].image}
                                className="image-fluid"
                                fluid
                                alt="Banner"
                            />
                            {bannerArray[bannerIndex].caption}
                        </Figure>
                        <Image />
                    </Col>
                </Row>

                {/* Featured Solutions */}
                <Row className="mx-0 mb-32px">
                    <Col>
                        <Card className="shadow">
                            <Card.Header className="bg-white border-bottom px-32px py-16px">
                                <h2 className="m-0">Featured Solutions</h2>
                            </Card.Header>
                            <Card.Body className="p-32px">
                                <Row noGutters>
                                    <Col xs={12} sm={6} md={4} className="p-16px d-flex flex-column align-items-start">
                                        <div>
                                            <OnlineStoreSVG width={80} height={80} className="mb-32px mr-16px" />
                                            {ACStoreURL && ACStoreName && enabledBadge}
                                        </div>
                                        <div>
                                            <h3 className="mb-16px mr-24px d-inline-block">Online Store</h3>
                                        </div>
                                        <p className="mb-16px">
                                            I want to build a brand new online store using Cart.com&apos;s online
                                            storefront software.
                                        </p>
                                        <Link to="/online-store" component={Button} className="mt-auto">
                                            {ACStoreURL && ACStoreName ? "Launch" : "Get Started"}
                                        </Link>
                                    </Col>

                                    <Col xs={12} sm={6} md={4} className="p-16px d-flex flex-column align-items-start">
                                        <div>
                                            <OrderFulfillmentSVG width={80} height={80} className="mb-32px mr-16px" />
                                            {appMetadata?.provisioningStatus?.wms
                                                ? enabledBadge
                                                : appMetadata?.requestStatus?.orderFulfillment && requestedBadge}
                                        </div>
                                        <h3 className="mb-16px">Order Fulfillment</h3>
                                        <p className="mb-16px">
                                            Ship products faster with our nationwide fulfillment network.
                                        </p>
                                        <Link to="/order-fulfillment" component={Button} className="mt-auto">
                                            {appMetadata?.provisioningStatus?.wms ? "Launch" : "Get Started"}
                                        </Link>
                                    </Col>

                                    <Col xs={12} sm={6} md={4} className="p-16px d-flex flex-column align-items-start">
                                        <div>
                                            <UnifiedAnalyticsSVG width={80} height={80} className="mb-32px mr-16px" />
                                            {appMetadata?.provisioningStatus?.mia && enabledBadge}
                                        </div>
                                        <h3 className="mb-16px">Unified Analytics</h3>
                                        <p className="mb-16px">
                                            Experience end-to-end analytics across your shopper journey as you attract
                                            and fulfill buyer demand.
                                        </p>
                                        <Link to="/unified-analytics" component={Button} className="mt-auto">
                                            {appMetadata?.provisioningStatus?.mia ? "Launch" : "Get Started"}
                                        </Link>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

                {/* Featured Services */}
                <Row className="mx-0">
                    <Col>
                        <Card className="shadow">
                            <Card.Header className="bg-white border-bottom px-32px py-16px">
                                <h2 className="m-0">Featured Services</h2>
                            </Card.Header>
                            <Card.Body className="p-32px">
                                <Row noGutters>
                                    <Col xs={12} sm={6} md={4} className="p-16px d-flex flex-column align-items-start">
                                        <div>
                                            <MarketingServicesSVG width={80} height={80} className="mb-32px mr-16px" />
                                            {appMetadata?.requestStatus?.marketingServices && requestedBadge}
                                        </div>
                                        <h3 className="mb-16px">Marketing Services</h3>
                                        <p className="mb-16px">
                                            Expert marketing services for omnichannel sales growth.
                                        </p>
                                        <Link
                                            to="/marketing-services"
                                            component={Button}
                                            className="btn-secondary mt-auto"
                                        >
                                            Learn More
                                        </Link>
                                    </Col>
                                    <Col xs={12} sm={6} md={4} className="p-16px d-flex flex-column align-items-start">
                                        <div>
                                            <CustomerServiceSVG width={80} height={80} className="mb-32px mr-16px" />
                                            {appMetadata?.requestStatus?.customerService && requestedBadge}
                                        </div>
                                        <h3 className="mb-16px">Customer Service</h3>
                                        <p className="mb-16px">
                                            Increase satisfaction and sales with a white-labeled customer service team.
                                        </p>
                                        <Link
                                            to="/customer-service"
                                            component={Button}
                                            className="btn-secondary mt-auto"
                                        >
                                            Learn More
                                        </Link>
                                    </Col>
                                    <Col xs={12} sm={6} md={4} className="p-16px d-flex flex-column align-items-start">
                                        <div>
                                            <BusinessPlanningSVG width={80} height={80} className="mb-32px mr-16px" />
                                            {appMetadata?.requestStatus?.businessPlanning && requestedBadge}
                                        </div>
                                        <h3 className="mb-16px">Business Planning</h3>
                                        <p className="mb-16px">
                                            Data-driven growth planning for multichannel ecommerce.
                                        </p>
                                        <Link
                                            to="/business-planning"
                                            component={Button}
                                            className="btn-secondary mt-auto"
                                        >
                                            Learn More
                                        </Link>
                                    </Col>
                                    <Col xs={12} sm={6} md={4} className="p-16px d-flex flex-column align-items-start">
                                        <div>
                                            <DataScienceSVG width={80} height={80} className="mb-32px mr-16px" />
                                            {appMetadata?.requestStatus?.dataScience && requestedBadge}
                                        </div>
                                        <h3 className="mb-16px">Data Science</h3>
                                        <p className="mb-16px">
                                            Learn about current and upcoming capabilities leveraging machine learning
                                            and automation to acquire, convert, and fulfill shopper demand at scale.
                                        </p>
                                        <Link to="/data-science" component={Button} className="btn-secondary mt-auto">
                                            Learn More
                                        </Link>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <Footer />
        </>
    );
};
