import React, { ReactElement } from "react";
import { Container, Carousel, Image, Row, Col, Card } from "react-bootstrap";

import image0 from "images/gallery/image22744.png";
import image1 from "images/gallery/image22745.png";
import image2 from "images/gallery/image22746.png";
import { ReactComponent as ChevronRightSVG } from "images/svgs/chevron-right.svg";
import { ReactComponent as ChevronLeftSVG } from "images/svgs/chevron-left.svg";

type ImageGalleryProps = {
    className?: string;
};

export function ImageGallery({ className }: ImageGalleryProps): ReactElement {
    const [index, setIndex] = React.useState(0);

    const handleSelect = (selectedIndex) => {
        setIndex(selectedIndex);
    };

    return (
        <Container style={{ maxWidth: 850 }} className={className}>
            <Carousel
                className="shadow"
                activeIndex={index}
                onSelect={handleSelect}
                interval={null}
                indicators={false}
                prevIcon={
                    <Card className="shadow border" style={{ position: "absolute", left: -50 }}>
                        <ChevronLeftSVG height={28} width={28} className="p-6px" />
                    </Card>
                }
                nextIcon={
                    <Card className="shadow border" style={{ position: "absolute", right: -50 }}>
                        <ChevronRightSVG height={28} width={28} className="p-6px" />
                    </Card>
                }
            >
                <Carousel.Item>
                    <Image src={image0} className="w-100" />
                </Carousel.Item>
                <Carousel.Item>
                    <Image src={image1} className="w-100" />
                </Carousel.Item>
                <Carousel.Item>
                    <Image src={image2} className="w-100" />
                </Carousel.Item>
            </Carousel>
            <Row className="mt-4">
                <Col xs={4}>
                    <Image
                        onClick={() => setIndex(0)}
                        src={image0}
                        thumbnail
                        className={`${index === 0 ? "border-primary" : "border-0"} shadow`}
                        style={{ cursor: "pointer" }}
                    />
                </Col>
                <Col xs={4}>
                    <Image
                        onClick={() => setIndex(1)}
                        src={image1}
                        thumbnail
                        className={`${index === 1 ? "border-primary" : "border-0"} shadow`}
                        style={{ cursor: "pointer" }}
                    />
                </Col>
                <Col xs={4}>
                    <Image
                        onClick={() => setIndex(2)}
                        src={image2}
                        thumbnail
                        className={`${index === 2 ? "border-primary" : "border-0"} shadow`}
                        style={{ cursor: "pointer" }}
                    />
                </Col>
            </Row>
        </Container>
    );
}
